import React, { useContext, useEffect, useRef, useMemo } from 'react';
import { PrintReaderContext } from '../../hooks/printReaderContext';
import PrintPage from "./PrintPage";
import { PrintPageProvider } from "../../hooks/printPageContext";
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import useZoomStatus from "../../hooks/useZoomStatus";
import usePageTransitionStatus from "../../hooks/usePageTransitionStatus";
import PrintControls from "./PrintControls";

const PrintPageChanger = () => {
    const {
        printReaderState,
        printReaderDispatch,
        moveForwardInBook,
        moveBackwardInBook,
        togglePaneling
    } = useContext( PrintReaderContext );
    const pageHolderRef = useRef();
    const zoomStatus = useZoomStatus({
        pageHolderRef,
        printReaderState
    });

    const {
        isBookReadyToRead,
        changeType,
        curBook,
        curPageNum,
        isAnimating,
        isSmartPanelingOn,
        prevPageNum,
        prevPanelNum,
        isInZoomMode,
        curPanelNum,
        curPanels,
        direction,
        areBookImagesLoading,
        prevPageInfo,
        curPageInfo
    } = printReaderState;
    const pageTransitionStatus = usePageTransitionStatus({
        atPageNum: curPageNum || 0,
        printReaderDispatch
    });
    const {
        toAlpha,
        atPageNum,
        goToPage,
        fromSlidePos,
        toSlidePos
    } = pageTransitionStatus;

    const isPageChanging = !!(
        isBookReadyToRead &&
        (
            changeType === 'page'
        )
    );

    let alpha = ( isPageChanging ) ? toAlpha : null;

    const pageRect = useMemo(() => {
        const pageInfo = get( curBook, `pages[${ curPageNum }]` );
        return ( pageInfo ) ?
            {
                width: get( pageInfo, 'width' ),
                height: get( pageInfo, 'height' ),
                x: 0,
                y: 0,
                maskColor: '#000000'
            } :
            null;
    }, [
        curBook,
        curPageNum
    ]);

    const ghostPanelRect = useMemo(() => {
        let rect = null;
        if( isPageChanging ) {
            if( isSmartPanelingOn ) {
                const ghostPanelInfo = get( curBook, `pages[${ prevPageNum }].panels[${ prevPanelNum }]` );
                rect = {
                    x: get( ghostPanelInfo, 'x' ),
                    y: get( ghostPanelInfo, 'y' ),
                    width: get( ghostPanelInfo, 'width' ),
                    height: get( ghostPanelInfo, 'height' ),
                    maskColor: get( ghostPanelInfo, 'mask_color' )
                };
            } else {
                rect = cloneDeep( pageRect );
                if( isInZoomMode ) {
                    rect.containerWidth = pageRect.width;
                    rect.containerHeight = pageRect.height;
                }

            }
        }
        return rect;
    },[
        isPageChanging,
        isSmartPanelingOn,
        curBook,
        prevPageNum,
        prevPanelNum,
        pageRect,
        isInZoomMode
    ]);

    let atPanelNum;
    if ( isSmartPanelingOn ) {
        atPanelNum = curPanelNum;

    } else if( !isSmartPanelingOn && !isInZoomMode ) {
        atPanelNum = 'page';
    } else if( !isSmartPanelingOn && isInZoomMode ) {
        atPanelNum = 'zoom';
    }
    const curPanel = useMemo(() =>get( curPanels, `[${curPanelNum}]` ), [ curPanels, curPanelNum ]);

    const shouldChangePage = !!(
        !isAnimating &&
        isBookReadyToRead &&
        atPageNum !== curPageNum
    );

    useEffect(() => {
        if( shouldChangePage ) {
            goToPage(
                curPageNum,
                isSmartPanelingOn,
                curPanel,
                direction,
                isInZoomMode
            );
        }
    }, [
        shouldChangePage,
        curPageNum,
        isSmartPanelingOn,
        curPanel,
        direction,
        isInZoomMode,
        goToPage
    ]);

    // Page-level animation should just be for when preloading images.
    return (
        <PrintControls
            moveForwardInBook = { moveForwardInBook }
            moveBackwardInBook = { moveBackwardInBook }
            togglePaneling = { togglePaneling }
            isInZoomMode = { isInZoomMode }
        >
            <div
                id = "page"
                ref = { pageHolderRef }
                className = {`
                    ${ ( areBookImagesLoading ) ? 'page-loading-animation' : '' }
                    ${ ( !isSmartPanelingOn ) ? 'stripes' : '' }
                    ${ ( isInZoomMode ) ? 'zoomish' : '' }
                    ${ ( zoomStatus.isGrabbing ) ? 'grabbing' : '' }`
                }
            >
                {
                    isPageChanging &&
                    <PrintPage
                        key = { 'page_' + prevPageNum }
                        id = { 'ghost_' + prevPageNum }
                        pageWidth = { prevPageInfo.width }
                        pageHeight = { prevPageInfo.height }
                        imageURL = { prevPageInfo.url }
                        alpha = { 1 }
                        isGhostPage = { true }
                        ghostPanelRect = { ghostPanelRect }
                        leftPos = { fromSlidePos }
                    />
                }
                {
                    isBookReadyToRead &&
                    <PrintPageProvider
                        key = { 'page_' + curPageNum }
                        pageNum = { curPageNum }
                        atPanelNum = { atPanelNum }
                        pageRect = { pageRect }
                    >
                        <PrintPage
                            key = { 'page_' + curPageNum }
                            id = { 'page_' + curPageNum }
                            width = { curPageInfo.width }
                            height = { curPageInfo.height }
                            imageURL = { curPageInfo.url }
                            alpha = { alpha }
                            pageRect = { pageRect }
                            leftPos = { toSlidePos }
                        />
                    </PrintPageProvider>
                }
            </div>
        </PrintControls>
    );
};

export default PrintPageChanger;