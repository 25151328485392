import { useEffect, useReducer, useCallback } from 'react';

const keysReducer = ( state, action ) => {
    switch ( action.type ) {
    case 'keyDown':
        return { ...state, [ action.key ]: true };
    case 'keyUp':
        return { ...state, [ action.key ]: false };
    default:
        return state;
    }
};

const useKeyPress = ( shortcutKeys, callback ) => {
    const initalKeyMapping = {};
    for( let i = 0; i < shortcutKeys.length; i++ ) {
        const key = shortcutKeys[ i ].toLowerCase();
        initalKeyMapping[ key ] = false;
    }

    const [ keys, setKeys ] = useReducer( keysReducer, initalKeyMapping );

    const _onKeydown = useCallback(( e ) => {
        let { key, target, repeat } = e;
        key = key.toLowerCase();
        if(
            !repeat &&
            keys[ key ] !== undefined &&
            target !== 'INPUT' &&
            target !== 'TEXTAREA'
        ) {
            setKeys({
                type: 'keyDown',
                key
            });
        }
    }, [
        keys
    ]);

    const _onKeyup = useCallback(( e ) => {
        let { key, target, repeat } = e;
        key = key.toLowerCase();
        if(
            !repeat &&
            keys[ key ] !== undefined &&
            target !== 'INPUT' &&
            target !== 'TEXTAREA'
        ) {
            setKeys({
                type: 'keyUp',
                key
            });
        }
    }, [
        keys
    ]);

    useEffect(() => {
        let isKeyDown = true;
        for( const key in keys ) {
            if( keys.hasOwnProperty( key ) ) {
                isKeyDown = !!( isKeyDown && keys[ key ] );
            }
        }
        if( isKeyDown && callback ) {
            callback( keys );
        }
    }, [
        keys,
        callback
    ]);

    useEffect(() => {
        window.addEventListener( 'keydown', _onKeydown, true );
        return () => {
            window.removeEventListener( 'keydown', _onKeydown, true );
        };
    }, [
        _onKeydown
    ]);


    useEffect(() => {
        window.addEventListener( 'keyup', _onKeyup, true );
        return () => {
            window.removeEventListener( 'keyup', _onKeyup, true );
        };
    }, [
        _onKeyup
    ]);

};

export default useKeyPress;