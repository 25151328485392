import ComponentService from './component.service';
import { get, isObject, cloneDeep, findIndex } from 'lodash';

export default class BifrostService extends ComponentService {
    // Needs to be overridden to avoid conflicts on extension
    static instance = null;

    _curBookId = null;
    _bookData = {};

    get curBookData() {
        let issueData = null;
        if( this.curBookId && this._bookData[ this.curBookId ] ) {
            const curBook = this._bookData[ this.curBookId ];
            issueData = cloneDeep( get( curBook, 'issueData.formatted' ) );
            issueData.discoverData = cloneDeep( get( curBook, 'discoverData.formatted' ) );
        }
        return issueData;
    }
    set curBookData( obj ) {
        if( obj ) {
            this._bookData[ obj.bookId ] = obj;
            this.curBookId = obj.bookId;
        }
    }

    set curBookId( value ) {
        if( value && this._curBookId !== value ) {
            this._curBookId = value;
            // component service has logic that will split this into two events: bookUpdate & bookUpdate:[bookId]
            // so that you can subscribe to information from a specific book or just in general
            this.trigger(
                'bookDataUpdate:' + value,
                {
                    curBookData: this.curBookData
                }
            );
        }
    }
    get curBookId() {
        return this._curBookId;
    }

    async loadBookById( bookId, bookSourceType, displayFormat ) {
        const endpoint = 'digital-comics';
        let bookData = this._bookData[ bookId ] || null;
        let error;

        if( !bookData ) {
            let issueURL;
            let assetURL;
            let discoverURL;
            let grootMetaURL;
            let wasCallSuccessful;
            let issueCredentials = 'omit';
            let comicFormat = (displayFormat !== null ? '?format=' + displayFormat : '');

            switch( bookSourceType ) {
            case 'grootbook':
                issueURL = process.env.REACT_APP_GROOT_API + '/api/reader/' + bookId + comicFormat;
                grootMetaURL = process.env.REACT_APP_API_HOST + '/v1/catalog/' + endpoint + '/metadata/' + bookId + comicFormat;
                issueCredentials = 'include';
                break;
            case 'labelbook':
            case 'kidsbook':
            case 'book':
            default:
                issueURL = process.env.REACT_APP_API_HOST + '/v1/catalog/' + endpoint + '/metadata/' + bookId;
                assetURL = process.env.REACT_APP_API_HOST + '/v1/catalog/' + endpoint + '/web/assets/' + bookId;
                break;
            }
            
            let issueData = {
                "response": {
                    "code": 404
                }};
            let grootMeta = {
                "response": {
                    "code": 404
                }};
            let assetData = {
                "response": {
                    "code": 404
                }};
            let discoverData = {"rawResponse": {}};
            let catalogId;

            if(process.env.REACT_APP_MOCK_DATA) {
                // Convert Module Object to a primative Object
                if(bookSourceType === 'grootbook') {
                    issueData.response = await import('./__mocks__/groot.json').then( response => { return {...response};}); 
                    grootMeta.response = await import('./__mocks__/metadata.json').then( response => { return {...response};});
                } else {
                    issueData.response = await import('./__mocks__/metadata.json').then( response => { return {...response};}); 
                    assetData.response = await import('./__mocks__/webassets.json').then( response => { return {...response};});
                    catalogId = get( issueData, 'response.data.results[0].issue_meta.catalog_id' );
                    discoverData.response = await import('./__mocks__/discovery.json').then( response => { return {...response};});
                }
                // console.log("///// MOCK MODE", {issueData, grootMeta});
            } else {
                issueData = await this.callRogue( issueURL, { credentials: issueCredentials } );

                if(grootMetaURL) {
                    grootMeta = await this.callRogue( grootMetaURL, { credentials: 'omit' } );
                }
                
                assetData = ( assetURL ) ? await this.callRogue( assetURL, { credentials: 'include' } ) : null;
                catalogId = get( issueData, 'response.data.results[0].issue_meta.catalog_id' );
                discoverURL = ( catalogId ) ? process.env.REACT_APP_FRONTEND_API 
                                                + '/content/discover/module?issueId=' 
                                                + catalogId 
                                                + '&getContent=1&platform=marvel_site'
                                            : null;
                discoverData = ( discoverURL ) ? await this.callRogue( discoverURL ) : null;
                // console.log("///// BIFROST URLS: ", {issueURL, grootMetaURL, assetURL, discoverURL});
            }

            switch( bookSourceType ) {
            case 'grootbook':
                wasCallSuccessful = !!(
                    issueData.response.code !== 404
                );
                break;
            case 'book':
            default:
                wasCallSuccessful = !!(
                    issueData.response.code === 200 &&
                    assetData.response.code === 200 &&
                    get( issueData, 'response.data.results[0].pages' ).length &&
                    get( assetData, 'response.data.results[0].pages' ).length
                );
                break;
            }

            if( wasCallSuccessful ) {
                switch( bookSourceType ) {
                case 'grootbook':
                    issueData.formatted = cloneDeep( get( issueData, 'response' ) );
                    grootMeta = get( grootMeta, 'response.data.results[0].issue_meta' );
                    this._massageGrootData( issueData.formatted, grootMeta, displayFormat );
                    break;
                case 'book':
                default:
                    issueData.formatted = cloneDeep( get( issueData, 'response.data.results[0]' ) );
                    assetData.formatted = ( assetData ) ? cloneDeep( get( assetData, 'response.data.results[0]' ) ) : null;
                    if(discoverData && discoverData?.response?.code !== 404) {
                        discoverData.formatted = ( discoverData ) ? cloneDeep( get( discoverData, 'response.data.results' ) ) : null;
                        this._massageDiscoverData( discoverData.formatted, catalogId );
                    }
                    this._combineAssetDataWithIssueData( issueData.formatted, assetData.formatted );
                    break;
                }
                // console.log("///// BIFROST DATA:", { catalogId, bookId, issueData, assetData, discoverData });
                this.curBookData = bookData = {
                    catalogId,
                    bookId,
                    issueData,
                    assetData,
                    discoverData
                };


            } else {
                // console.log("///// BIFROST ERROR:", { catalogId, bookId, issueData, assetData, discoverData });
                error = {
                    didErrorOut: true,
                    issueError: {
                        code: issueData.response.code,
                        status: issueData.response.status
                    }
                };

                error.assetError = ( assetData ) ?
                    {
                        code: assetData.response.code,
                        status: assetData.response.status
                    }:
                    null;
            }
        } else {
            this.curBookId = bookId;
        }

        return error || this.curBookData;
    }

    async callRogue(
        url, // required
        options = {},
    ) {
        const {
            params = null,
            method = 'GET',
            headers = null,
            mode = 'cors',
            credentials = 'omit'
        } = options;

        let data = {};
        const requestHeader = new Headers();

        if( headers ) {
            for( let key in headers ) {
                if( headers.hasOwnProperty( key ) ) {
                    requestHeader.append( key, headers[ key ] );
                }
            }
        }

        const requestOptions = {
            method: method,
            headers: requestHeader,
            mode: mode,
            credentials: credentials
        };

        if( params && method === 'GET' ) {
            const qs = Object.keys( params )
                .map( key => `${encodeURIComponent( key )}=${encodeURIComponent( params[ key ])}` )
                .join('&');

            url += ( url.includes( '?' ) ) ? '&' + qs : '?' + qs;
        } else if( params && method === 'POST' ) {
            if( isObject( params ) ) {
                requestOptions.body = new FormData();
                Object.keys( params ).forEach( params => {
                    requestOptions.body.append( params, data[ params ]);
                });
            } else {
                requestOptions.body = params;
            }
        }

        const request = new Request( url, requestOptions );
        // eslint-disable-next-line require-atomic-updates
        data.rawResponse = await fetch( request ).catch( ( e ) => data.error = e );
        // eslint-disable-next-line require-atomic-updates
        data.response = ( data.rawResponse.json ) ?
            await data.rawResponse.json().catch( ( e ) => data.error = e ) :
            {
                code: 404
            };

        return data;
    }

    /**
     * Combines data from the Assets API with the Meta API
     * @param issueData - Comic data isolated from the API response.
     * @param assetData - Comic Assets data isolated from the API response.
     * issueData is mutated.
     */
    _combineAssetDataWithIssueData( issueData, assetData ) {
        if( issueData && assetData ) {
            let loginURL = process.env.REACT_APP_LOGIN_URL + '?referer=' + window.encodeURIComponent( window.location.href );
            issueData.external_links.login = loginURL;
            issueData.auth_state = assetData.auth_state;
            issueData.partner_logo = assetData.partner_logo || {};
            issueData.is_preview = ( !issueData.is_free && ( !issueData.auth_state.logged_in || !issueData.auth_state.subscriber ) );
            issueData.numberOfPages = assetData.pages.length;
            issueData.issue_meta.reader_format = ["Digital Vertical Comic"].includes(issueData.issue_meta.format) ? "vertical" : "print";

            for( let i = 0; i < issueData.numberOfPages; i++ ) {
                const issuePage = issueData.pages[ i ];
                const assetPage = assetData.pages[ i ];
                if(issuePage) {
                    issuePage.sequence = i;
                    issuePage.url = assetPage.assets.source;
                    issuePage.thumbnail = assetPage.assets.thumbnail;
                    if( issuePage.is_legacy ) {
                        issuePage.height = 756;
                    }
                    if(issueData.issue_meta.format === "Infinite Comic" && !issuePage.panels.length) {
                        issuePage.panels = [
                          {
                            id: "nopanel",
                            type: "rectangle",
                            sequence: 0,
                            x: 0,
                            y: 0,
                            width: issuePage.width,
                            height: issuePage.height,
                            mask_color: "#000000",
                            transitions_forward: [
                              {
                                type: "crossfade",
                                duration: 0.6,
                                direction: "none",
                              },
                            ],
                          },
                        ];
                    }
                }
            }
        }
    }

    _massageDiscoverData( discoverData, catalogId ) {
        if( discoverData ) {
            for (let i = 0; i < discoverData.length; i++) {
                const spotlight = discoverData[i];
                spotlight.bookIndex = findIndex( spotlight.contents, comic => comic.id === catalogId );
            }
        }
    }


    /**
     * Reshapes the data closer to a Bifrost call for the read to ingest. 
     * @param grootData - Original data from the Groot API
     * @param grootMeta - Suppliments Groot API with Bifrost meta data of the same book.
     * @returns the modified `grootData` object.
     */
    _massageGrootData( grootData, grootMeta = {}, displayFormat ) {
        if( grootData ) {
            // Combines the default issue_meta object with Bifrost metadata and overrides the reader format for the groot setting
            grootData.issue_meta = Object.assign({
                title: grootData.title,
                creators: {extended_list:[]},
                thumbnail: {},
            }, grootMeta, {reader_format: grootData.digital_format});
            
            for( let i = 0; i < grootData.pages.length; i++ ) {
                const page = grootData.pages[ i ];
                page.sequence = page.order_nr;
                page.thumbnail = page.thumbnail || page.url;
                page.is_ad = ( page.is_ad === '1' );
                if( page.is_legacy ) {
                    page.height = 756;
                }
                if(grootData.format === "Infinite Comic" && !page.panels.length){
                    page.panels = [
                        {
                            id: "2867548",
                            book_id: page.book_id,
                            page_id: page.id,
                            sequence: 0,
                            type: "rectangle",
                            x: 0,
                            y: 0,
                            audio_event_key: "0",
                            height: page.height,
                            width: page.width,
                            mask_color: "#000000",
                            transitions: {
                            forward: [
                                {
                                id: 0,
                                type: "pan",
                                duration: 0.6,
                                direction: "none",
                                },
                            ],
                            reverse: [
                                {
                                id: 0,
                                type: "pan",
                                duration: 0.6,
                                direction: "none",
                                },
                            ],
                        },
                      }
                    ];
                }
                for( let j = 0; j < page.panels.length; j++ ) {
                    const panel = page.panels[j];
                    panel.transitions_forward = panel.transitions.forward;
                }
            }
            
            grootData.numberOfPages = grootData.pages.length;
            grootData.is_free = true;
            grootData.partner_logo = grootData.partner_logo || {};
            grootData.auth_state = {};
            
            return grootData;
        }
    }

    _massageKidsBookData( issueData ) {
        issueData.is_preview = false;
    }
}