import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SpotlightSlide from "./SpotlightSlide";
import Slick from "react-slick";

const SpotlightSlider = ( props ) => {
    const {
        slides,
        bookIndex
    } = props;
    const length = slides.length;
    const slideEls = useMemo(() => {
        const els = [];
        if( slides ) {
            for( let i = 0; i < slides.length; i++ ) {
                const book = slides[ i ];
                els.push(
                    <SpotlightSlide
                        key = { `slide-${i}`}
                        digitalId = { book.digital_id }
                        slideNum = { i }
                        title = { book.title }
                        thumbnailPath = { book.thumbnail.path }
                        creatorsShort = { book.creators_short }
                    />
                );
            }
        }
        return els;
    }, [
        slides
    ]);

    const slickSettings = useMemo(() => {
        const isShort = !!( length < 4 );

        return{
            slidesToShow: 4,
            centerMode: !isShort,
            centerPadding: ( isShort ) ? '150px' : '60px',
            infinite: true,
            initialSlide: ( isShort ) ? 0 : bookIndex + 1,
            variableWidth: isShort,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 570,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 320,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };
    },[
        bookIndex,
        length
    ]);

    return (
        <div className="inner_sl_cont">
            <div className="slider_cont">
                <Slick
                    {...slickSettings}
                >
                    { slideEls }
                </Slick>
            </div>
        </div>
    );
};

SpotlightSlider.propTypes = {
    slides: PropTypes.array,
    bookIndex: PropTypes.number
};

export default SpotlightSlider;