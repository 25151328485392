import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PrintPageContext } from "../../hooks/printPageContext";

const PageContainer = ( props ) => {
    const printPageContext = useContext( PrintPageContext );

    const curPageInfo = ( printPageContext ) ? printPageContext.curPageInfo : null;
    let isAd;
    let adURL;

    if( curPageInfo ) {
        isAd = curPageInfo.is_ad;
        adURL = curPageInfo.ad_url;
    }

    const hasAd = ( isAd && adURL );

    const style = {
        display: 'block',
        left: props.leftPos + '%',
        backgroundColor: props.maskColor,
        opacity: props.alpha
    };

    const onAdClick = useCallback(() => {
        window.open( adURL );
    }, [
        adURL
    ]);

    return (
        <svg
            id={ props.id }
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={ props.containerWidth }
            height={ props.containerHeight }
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className={ `svg-el ${ hasAd ? 'is-clickable clickable-area' : '' }` }
            style={ style }
            viewBox={`${props.x},${props.y},${props.width},${props.height}`}
            onClick = { hasAd ? onAdClick : null }
        >
            {props.children}
        </svg>

    );
};

PageContainer.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
    alpha: PropTypes.number,
    leftPos: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    containerWidth: PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] ),
    containerHeight: PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] ),
    x: PropTypes.number,
    y: PropTypes.number,
    maskColor: PropTypes.string
};

export default PageContainer;