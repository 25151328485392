import React from 'react';
import PropTypes from 'prop-types';
import useLazyLoadStatus from '../../hooks/useLazyLoadStatus';
import emptyPixel from '../../images/empty-white.gif';

const VerticalPanel = ( props ) => {
    const {
        panelNum,
        width,
        height
    } = props;

    const elId = 'vertical-panel-' + panelNum;
    const { hasIntersected } = useLazyLoadStatus({ elId, height, panelNum });
    const src = ( hasIntersected && props.src ) ? props.src : emptyPixel;

    return (
        <img
            id = { elId }
            className = "panel-image"
            src = { src }
            width = { width }
            height = { height }
            alt = { props.alt }
            style = {{
                width,
                height
            }}
        />
    );
};

VerticalPanel.propTypes = {
    panelNum: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    alt: PropTypes.string,
    src: PropTypes.string,
};

export default VerticalPanel;