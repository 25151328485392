import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { manageDataLayer, uatuActions } from '../hooks/uatu';


const PreviewBox = ( props ) => {
    const _onLinkClick = useCallback(( url, type ) => {
        manageDataLayer({
            type: uatuActions.REPORT_LINK_CLICKED,
            payload: {
                type,
                url
            }
        });
    },[]);
    return (
        <section
            id="preview-box"
        >
            <div className ="preview-box">
                <section className="text-container">
                    <h3>PREVIEW MODE</h3>
                    {
                        ( props.isLoggedIn ) ?
                            <p>
                                You&apos;re currently viewing this comic in preview mode. Become a Marvel Unlimited member to continue reading now!
                            </p>
                            :
                            <p>
                                You&apos;re currently viewing this comic in preview mode. Continue&nbsp;
                                reading by becoming a Marvel Unlimited member or, if already a&nbsp;
                                member, sign in below.
                            </p>
                    }
                </section>
                {
                    ( props.isLoggedIn ) ?
                        <a
                            className="preview-btn learn-more width-100"
                            href = { process.env.REACT_APP_CHECKOUT }
                            onClick = {() => {
                                _onLinkClick( process.env.REACT_APP_CHECKOUT, 'preview_mode:join' );
                            }}
                        >
                            GET MARVEL UNLIMITED!
                        </a>
                        :
                        <div>
                            <a
                                className="preview-btn learn-more width-50"
                                href = { process.env.REACT_APP_CHECKOUT }
                                onClick = {() => {
                                    _onLinkClick( process.env.REACT_APP_CHECKOUT, 'preview_mode:join' );
                                }}
                            >
                                BECOME A MEMBER
                            </a>
                            <a
                                className="preview-btn signin width-50"
                                href = { process.env.REACT_APP_LOGIN_URL }
                                onClick = {() => {
                                    _onLinkClick( process.env.REACT_APP_LOGIN_URL, 'preview_mode:signin' );
                                }}
                            >
                                SIGN IN
                            </a>
                        </div>
                }
                <div className="clearfix"></div>
            </div>
        </section>
    );
};

PreviewBox.propTypes = {
    isLoggedIn: PropTypes.bool
};

export default PreviewBox;