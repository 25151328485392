import React, { createContext, useReducer, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    printReaderReducer,
    initialPrintReaderState,
    printReaderActions
} from "./printReaderReducer";
import filter from 'lodash/filter';
import useUatuForPrintReader from './useUatuForPrintReader';

export const PrintReaderContext = createContext();

export const PrintReaderProvider = ( props ) => {
    const {
        curPageNum,
        curBookId,
        curBook,
        curPanelNum,
        children
    } = props;

    const initialState = {
        ...initialPrintReaderState,
        curBook: curBook,
        curBookId: curBookId,
        isSmartPanelingOn: window?.digitalData?.comicDetail?.readingMode !== 'page'
    };

    const [ printReaderState, printReaderDispatch ] = useReducer(
        printReaderReducer,
        initialState
    );
    // eslint-disable-next-line no-unused-vars
    const analyitcs = useUatuForPrintReader({
        printReaderState
    });

    useEffect(() => {
        const _preloadBookPage = ( index, readablePages ) => {
            if( index < readablePages.length ) {
                const page = readablePages[ index ];
                const pageImage = new Image();
                const thumbImage = new Image();
                pageImage.onload = () => {
                    printReaderDispatch({
                        type: printReaderActions.PAGE_IMAGE_PRELOADED,
                        payload: {
                            index
                        }
                    });
                    _preloadBookPage( index + 1, readablePages );
                };
                pageImage.onerror = () => {
                    printReaderDispatch({
                        type: printReaderActions.PAGE_IMAGE_ERRORED,
                        payload: {
                            index
                        }
                    });
                    _preloadBookPage( index + 1, readablePages );
                };
                pageImage.src = page.url;
                thumbImage.src = page.thumbnail;
            } else {
                printReaderDispatch({
                    type: printReaderActions.IMAGE_PRELOAD_ENDED
                });
            }
        };

        const _preloadBookPageImages = () => {
            const readablePages = filter( curBook.pages, obj => obj.url );
            printReaderDispatch({
                type: printReaderActions.IMAGE_PRELOAD_STARTED,
                payload: {
                    readablePages
                }
            });
            _preloadBookPage( 0, readablePages );
        };

        if( curBookId ) {
            const payload = {};
            if( curPageNum || curPageNum === 0 ) {
                payload.curPageNum = curPageNum;
            }
            if( curPanelNum || curPanelNum === 0 ) {
                payload.curPanelNum = curPanelNum;
            }

            printReaderDispatch({
                type: printReaderActions.UPDATE_PAGE_INFO,
                payload
            });
            _preloadBookPageImages();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ curBookId ]);

    const moveForwardInBook = useCallback(() => {
        printReaderDispatch({
            type: printReaderActions.MOVE_FORWARD_IN_BOOK
        });
    }, [ printReaderDispatch ]);

    const moveBackwardInBook = useCallback(() => {
        printReaderDispatch({
            type: printReaderActions.MOVE_BACKWARD_IN_BOOK
        });
    }, [ printReaderDispatch ]);

    const goToPageByNumber = useCallback(( pageNum ) => {
        printReaderDispatch({
            type: printReaderActions.GO_TO_PAGE_BY_NUMBER,
            payload: {
                toPage: pageNum
            }
        });
    }, [ printReaderDispatch ]);

    const closeEndScreen = useCallback(() => {
        printReaderDispatch({
            type: printReaderActions.CLOSE_ENDSCREEN
        });
    }, [ printReaderDispatch ]);

    const togglePaneling = useCallback(
        (doubleTap = false) => {
          printReaderDispatch({
            type: printReaderActions.TOGGLE_PANELING,
            payload: {
              doubleTap,
            },
          });
        },
        [printReaderDispatch]
      );

    const toggleZoomMode = useCallback(() => {
        printReaderDispatch({
            type: printReaderActions.TOGGLE_ZOOM_MODE
        });
    }, [ printReaderDispatch ]);

    const toggleFullscreen = useCallback(() => {
        printReaderDispatch({
            type: printReaderActions.TOGGLE_FULLSCREEN
        });
    }, [ printReaderDispatch ]);

    const toggleAllPagesOpen = useCallback(() => {
        printReaderDispatch({
            type: printReaderActions.TOGGLE_ALL_PAGES_OPEN
        });
    }, [ printReaderDispatch ]);

    const toggleTooltipsOpen = useCallback(() => {
        printReaderDispatch({
            type: printReaderActions.TOGGLE_TOOLTIPS_OPEN
        });
    }, [ printReaderDispatch ]);

    const toggleBookInfoOpen = useCallback(() => {
        printReaderDispatch({
            type: printReaderActions.TOGGLE_BOOK_INFO_OPEN
        });
    }, [ printReaderDispatch ]);

    return (
        <PrintReaderContext.Provider
            value = {{
                printReaderState,
                printReaderDispatch,
                moveForwardInBook,
                moveBackwardInBook,
                goToPageByNumber,
                closeEndScreen,
                togglePaneling,
                toggleZoomMode,
                toggleFullscreen,
                toggleAllPagesOpen,
                toggleTooltipsOpen,
                toggleBookInfoOpen
            }}
        >
            { children }
        </PrintReaderContext.Provider>
    );
};

PrintReaderProvider.propTypes = {
    curBook: PropTypes.object.isRequired,
    curPanelNum: PropTypes.number,
    curPageNum: PropTypes.number,
    curBookId: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired
};
