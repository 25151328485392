/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import { debounce } from 'lodash';

const clickZonePercentage = 15;

const PrintControls = ( props ) => {
    const {
        children,
        moveForwardInBook,
        moveBackwardInBook,
        togglePaneling,
        isInZoomMode
    } = props;

    let isMouseDown = false;
    let isDragging = false;
    let firstTap = 0;
    let resetTimer;

    const _onSwipeLeft = useCallback(() => {
        if( !isInZoomMode ) {
            moveForwardInBook();
        }
    }, [ isInZoomMode ]);

    const _onSwipeRight = useCallback(() => {
        if( !isInZoomMode ) {
            moveBackwardInBook();
        }
    }, [ isInZoomMode ]);

    const resetTap = useCallback(() => {
        firstTap = 0;
    })
    const _onDoubleTap = useCallback(() => {
        // REACT-SWIPEABLE fires mouseup with onTap which fires roughly 2ms apart. 
        if(firstTap && Date.now() - firstTap > 50) {
            togglePaneling(true);
        } else {
            firstTap = Date.now();
            resetTimer = setTimeout(resetTap, 300); // Setting doubleTap threshold
        }
    }, [
        togglePaneling
    ]);

    const _onMouseDown = useCallback(() => {
        isMouseDown = true;
    });

    const _onMouseUp = ( {event} ) => {
        if(!isDragging) {
            const width = window.innerWidth;
            const leftArea = ( width * clickZonePercentage ) / 100;
            const rightArea = width - leftArea;
            if( event.pageX >= rightArea ) {
                moveForwardInBook();
            } else if( event.pageX <= leftArea ) {
                moveBackwardInBook();
            }
        }
    };

    const _onMouseMove = debounce(() => {
        if(isMouseDown) {
            isDragging = true;
        }
    }, 100);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: _onSwipeLeft,
        onSwipedRight: _onSwipeRight,
        onTap: _onDoubleTap,
        onTouchStartOrOnMouseDown: _onMouseDown,
        onTouchEndOrOnMouseUp: _onMouseUp,
        trackMouse: true,
    });

    return (
        <div
            onMouseMove = { _onMouseMove }
            {...swipeHandlers}
            role='button'
            tabIndex={0}
        >
            { children }
        </div>
    );
};

PrintControls.propTypes = {
    children: PropTypes.element.isRequired,
    isInZoomMode: PropTypes.bool,
    togglePaneling: PropTypes.func,
    moveForwardInBook: PropTypes.func,
    moveBackwardInBook: PropTypes.func
};

export default PrintControls;