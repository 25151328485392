import React from 'react';
import PropTypes from 'prop-types';

const InfoTooltips = ( props ) => {
    const {
        isSmartPanelingOn
    } = props.readerState;


    return (
        <section
            id = "info_tooltips"
            className = "_inView"
            onClick = { props.toggleTooltipsOpen }
            role = "button"
            tabIndex = "0"
        >
            <div className="toolTip_wrap">
                <button className="_dismiss">
                    <span
                        data-translation-txt-get-started="Get Started"
                        data-translation-txt-keep-reading="Keep Reading"
                    />
                </button>

                <div className="toolTip allPages"> <span>View all pages</span></div>
                <div className="toolTip showInfo"> <span>View comic info</span></div>
                <div className="toolTip help"> <span>View help screen</span></div>
                <div className="toolTip left_arrow"> <span>Previous</span></div>
                <div className="toolTip right_arrow"> <span>Next</span></div>
                { !isSmartPanelingOn &&
                    <div className="toolTip btn-panel full"> <span>Full Page View</span></div>
                }
                { isSmartPanelingOn && 
                    <div className="toolTip btn-panel"> <span>Panel View</span></div>
                }
                <div className="toolTip btn-zoom"> <span>Zoom In/Out</span></div>
                <div className="toolTip btn-screen"> <span>Full Screen</span></div>
            </div>
        </section>
    );
};

InfoTooltips.propTypes = {
    toggleTooltipsOpen: PropTypes.func,
    readerState: PropTypes.object
};

export default InfoTooltips;