import React, { useContext, useEffect, useMemo } from 'react';
import Header from '../Header';
import PrintPageChanger from './PrintPageChanger';
import PreviewBox from '../PreviewBox';
import Footer from '../Footer';
import BookInfo from '../BookInfo';
import LastView from '../LastView';
import InfoTooltips from '../InfoTooltips';
import AllPrintPages from './AllPrintPages';
import { PrintReaderContext } from '../../hooks/printReaderContext';
import get from 'lodash/get';
import { getIsFreePromo } from '../../util/helpers';

const Reader = () => {
    const {
        printReaderState,
        goToPageByNumber,
        toggleAllPagesOpen,
        toggleTooltipsOpen,
        toggleBookInfoOpen,
    } = useContext( PrintReaderContext );

    const {
        isAllPagesOpen,
        curBook,
        isBookInfoOpen,
        isTooltipsOpen,
        numPagesAvailable,
        curPageNum,
        isAtEndScreen,
        isFooterAvailable
    } = printReaderState;

    const {
        discoverData,
        is_preview,
        id
    } = curBook;

    const isFree = get( curBook, 'is_free' ) || false;
    const isFreePromo = getIsFreePromo(id);
    const isLoggedIn = get( curBook, 'auth_state.logged_in' ) || isFree;
    const isMUUser = get( curBook, 'auth_state.subscriber' ) || false;
    const pages = useMemo( () => get( curBook, 'pages' ), [ curBook ] );

    useEffect(() => {
        if( isAllPagesOpen ) {
            document.body.classList.add( 'overflow-hidden' );
        } else {
            document.body.classList.remove( 'overflow-hidden' );
        }
    }, [ isAllPagesOpen ]);

    const thumbnail = useMemo( () => get( curBook, 'issue_meta.thumbnail' ), [ curBook ] );
    const creators = useMemo( () => get( curBook, 'issue_meta.creators' ), [ curBook ] );
    const features = useMemo( () => get( curBook, 'issue_meta.features' ), [ curBook ] );
    const next_issue_meta = useMemo( () => get( curBook, 'prev_next_issue.next_issue_meta' ), [ curBook ] );
    return (
        <section id="rocket-view">
            <PrintPageChanger />
            { isAllPagesOpen &&
                <AllPrintPages
                    numberOfPages = { numPagesAvailable }
                    pages = { pages }
                    curPageNum = { curPageNum }
                    goToPageByNumber = { goToPageByNumber }
                    toggleAllPagesOpen = { toggleAllPagesOpen }
                />
            }
            { isAtEndScreen &&
                <LastView
                    nextIssueMeta = { next_issue_meta }
                    isMUUser = { isMUUser }
                    isLoggedIn = { isLoggedIn }
                    discoverData = { discoverData }
                    readerContext = { PrintReaderContext }
                    isFree={ isFree }
                    isFreePromo={ isFreePromo }
                />
            }
            { !isAtEndScreen &&
                <Header 
                    readerContext = { PrintReaderContext } 
                    readerState = { printReaderState }
                />
            }
            {( is_preview && !isAtEndScreen ) &&
                    <PreviewBox
                        isLoggedIn = { isLoggedIn }
                    />
            }
            { isFooterAvailable && !isAtEndScreen &&
                <Footer 
                    readerContext = { PrintReaderContext }
                    readerState = { printReaderState }
                />
            }
            { isBookInfoOpen &&
                <BookInfo
                    thumbnail = { thumbnail }
                    title = { get( curBook, 'issue_meta.title' ) }
                    releaseDateFormatted = { get( curBook, 'issue_meta.release_date_formatted' ) }
                    releaseDateDigitalFormatted = { get( curBook, 'issue_meta.release_date_digital_formatted' ) }
                    rating = { get( curBook, 'issue_meta.rating' ) }
                    creators = { creators }
                    imprint = { get( curBook, 'issue_meta.imprint' ) }
                    format = { get( curBook, 'issue_meta.reader_format' ) }
                    features = { features }
                    description = { get( curBook, 'issue_meta.description' ) }
                    toggleBookInfoOpen = { toggleBookInfoOpen }
                />
            }
            { isTooltipsOpen &&
                <InfoTooltips
                    toggleTooltipsOpen = { toggleTooltipsOpen }
                    readerState = { printReaderState }
                />
            }
            
        </section>
    );
};

export default Reader;