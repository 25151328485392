import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ( props ) => {
    const {
        numLoaded,
        totalToLoad,
        curProgressNum,
        totalProgressNum
    } = props;

    const loadingPercent = ( numLoaded / totalToLoad ) * 100;
    const progressPercent = ( ( curProgressNum + 1 ) / totalProgressNum ) * 100;

    return (
        <div className="progress-bar">
            <span
                className="loading"
                style = {{
                    width: loadingPercent + '%'
                }}
            />
            <span
                className="progress"
                style = {{
                    width: progressPercent + '%'
                }}
            />
        </div>
    );
};

ProgressBar.propTypes = {
    numLoaded: PropTypes.number,
    totalToLoad: PropTypes.number,
    curProgressNum: PropTypes.number,
    totalProgressNum: PropTypes.number
};

export default ProgressBar;