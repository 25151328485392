import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { uatuActions, manageDataLayer } from './uatu';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import extend from 'lodash/extend';

const useUatuForPrintReader = ( props ) => {
    const {
        printReaderState
    } = props;

    const {
        displayPageNum,
        displayPanelNum,
        totalPagesInBook,
        curPageInfo,
        curPanels,
        curBook,
        numPanelsOnPage,
        isSmartPanelingOn,
        isInZoomMode,
        isBookInfoOpen,
        isAtEndScreen,
        isTooltipsOpen,
        doubleTap
    } = printReaderState;
    const {
        issue_meta,
        auth_state,
        id,
        is_free
    } = curBook;

    const {
        title,
        release_date,
        rating,
        series_id,
        series_title,
        imprint,
        purchase_data,
        format
    } = issue_meta;

    const {
        logged_in,
        subscriber
    } = auth_state;

    useEffect(() => {
        if( id && totalPagesInBook ) {
            let partnerTitleWithId;
            // TODO: Grab this data point during or after white lable work
            // if(this.controlsModel.get( 'route' ) === 'labelbook') {
            //     partnerTitleWithId = ( partner_logo.id || '' ) +
            //     ' | ' +
            //     ( partner_logo.description || 'partnerbook' );
            // } else {
                partnerTitleWithId = 'generic';
            // }

            const creators = curBook?.issue_meta?.creators?.extended_list?.map(element => {
                return {[camelCase(element.role)]: element.full_name} || "";
            }) || [];

            manageDataLayer({
                type: uatuActions.REPORT_BOOK_LOAD,
                payload: extend({
                    appName: 'Marvel Unlimited',
                    availableDigital: '1',
                    content_format: format === "Comic" ? "standard" : "vertical",
                    coverVariant: '',
                    digital_comic_id: id,
                    digital_comic_title_with_id: id + ' | ' + title,
                    fullscreen: false,
                    imprint: imprint || 'Unknown',
                    isFree: is_free,
                    isPreview: ( !logged_in || !subscriber ),
                    partnerTitleWithId: partnerTitleWithId,
                    previewMode: ( !logged_in || !subscriber ),
                    price: get( purchase_data, '.digital_price' ),
                    publishDate: release_date,
                    rating: rating,
                    readingMode: 'smart panel',
                    seriesId: series_id,
                    seriesName: series_title,
                    tags: '',
                    totalPages: totalPagesInBook
                },
                ...creators)
            });
        }
    }, [
        title,
        release_date,
        rating,
        series_id,
        series_title,
        imprint,
        id,
        is_free,
        totalPagesInBook,
        logged_in,
        subscriber,
        purchase_data,
        curBook,
        format
    ]);

    useEffect(() => {
        if( curPageInfo && totalPagesInBook ) {
            manageDataLayer({
                type: uatuActions.REPORT_PAGE_OR_PANEL_CHANGE,
                payload: {
                    pageNumber: displayPageNum,
                    panelId: displayPanelNum,
                    totalPages: totalPagesInBook,
                    pageId: curPageInfo.id,
                    totalPanels: numPanelsOnPage
                }
            });
        }
    }, [
        totalPagesInBook,
        displayPageNum,
        displayPanelNum,
        curPageInfo,
        curPanels,
        numPanelsOnPage
    ]);

    useEffect(() => {
        if(totalPagesInBook) {
            let readingMode = ( isSmartPanelingOn ) ? 'smart panel' : 'page';
            readingMode = ( isInZoomMode ) ? 'page zoom' : readingMode;

            manageDataLayer({
                type: uatuActions.REPORT_READING_MODE,
                payload: {
                    readingMode,
                    doubleTap
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSmartPanelingOn, isInZoomMode, doubleTap]);

    useEffect(() => {
        let modalName;
        if( isBookInfoOpen ) {
          modalName = 'info';
        // } else if( isAllPagesOpen ) {
        //   modalName = 'toc';
        } else if (isTooltipsOpen) {
          modalName = "tip";
        } else if( isAtEndScreen ) {
          const isPreview = !!( !logged_in || !subscriber );
          modalName = ( isPreview ) ? 'eob:nonsub' : 'eob:sub';
        }
        if( modalName ) {
            manageDataLayer({
                type: uatuActions.REPORT_SHOW_MODAL,
                payload: {
                    modalName
                }
            });
        }
    }, [
        isBookInfoOpen,
        isTooltipsOpen,
        isAtEndScreen,
        logged_in,
        subscriber
    ]);

    useEffect(() => {
        if( !isAtEndScreen && totalPagesInBook ) {
            manageDataLayer({
                type: uatuActions.REPORT_END_SCREEN_CLOSE,
                payload: {
                    isAtEndScreen
                }
            });
        }
    }, [
        isAtEndScreen,
        totalPagesInBook
    ]);

    // useEffect(() => {
    //     manageDataLayer({
    //         type: uatuActions,
    //         payload: {
    //         }
    //     });
    // }, [
    // ]);

    return null;
};

useUatuForPrintReader.propTypes = {
    uatu: PropTypes.func,
    printReaderState: PropTypes.func
};

export default useUatuForPrintReader;