// import React from 'react';
export const printReaderActions = {
    UPDATE_PAGE_INFO: 'updatePageInfo',
    GO_TO_PAGE_BY_NUMBER: 'goToPageByNumber',
    GO_TO_NEXT_PAGE: 'goToNextPage',
    GO_TO_PREV_PAGE: 'goToPrevPage',

    GO_TO_PANEL_BY_NUMBER: 'goToPanelByNumber',
    GO_TO_NEXT_PANEL: 'goToNextPanel',
    GO_TO_PREV_PANEL: 'goToPrevPanel',

    MOVE_FORWARD_IN_BOOK: 'moveForwardInBook',
    MOVE_BACKWARD_IN_BOOK: 'moveBackwardInBook',

    OPEN_ENDSCREEN: 'openEndScreen',
    CLOSE_ENDSCREEN: 'closeEndScreen',
    START_BOOK_ANIMATION: 'startAnimation',
    END_BOOK_ANIMATION: 'endAnimation',
    ANIMATE: 'animate',
    IMAGE_PRELOAD_STARTED: 'imagePreloadStarted',
    IMAGE_PRELOAD_ENDED: 'imagePreloadEnded',
    PAGE_IMAGE_PRELOADED: 'pageImagePreloaded',
    PAGE_IMAGE_ERRORED: 'pageImageErroed',

    TOGGLE_PANELING: 'togglePaneling',
    TOGGLE_ZOOM_MODE: 'toggleZoomMode',
    TOGGLE_FULLSCREEN: 'toggleFullscreen',
    TOGGLE_ALL_PAGES_OPEN: 'toggleAllPagesOpen',
    TOGGLE_TOOLTIPS_OPEN: 'toggleTooltipsOpen',
    TOGGLE_BOOK_INFO_OPEN: 'toggleBookInfoOpen'
};

export const /* The above code is a comment in JavaScript. It is not doing anything in terms of code
execution, but it is used to provide information or explanations about the code to
other developers. */
initialPrintReaderState = {
    curBook: null,
    curBookId: null,

    // Zero-based page number for simple app logic
    curPageNum: null,
    prevPageNum: null,
    // Non-zero-based page number for UI/Analytics
    displayPageNum: null,
    curPageInfo: null,
    prevPageInfo: null,
    numPagesAvailable: 0,
    totalPagesInBook: 0,

    // Zero-based panel number
    curPanelNum: null,
    displayPanelNum: null,
    prevPanelNum: null,
    numPanelsOnPage: 0,

    isSmartPanelingOn: true,
    isInZoomMode: false,
    isAtEndScreen: false,
    isAllPagesOpen: false,
    isTooltipsOpen: false,
    isBookInfoOpen: false,

    isFooterLocked: false,
    isFooterAvailable: true,
    isHeaderLocked: false,

    isAnimating: false,
    direction: null,
    changeType: null,

    curPanels: null,

    areBookImagesLoading: false,
    isBookReadyToRead: false,
    numPageImagesPreloaded: 0,
    readablePages: null
};

export const printReaderReducer = ( state, action ) => {
    let toPanel = null;
    let fromPanel = null;
    let toPage = null;
    let readablePage = null;
    let readablePages = state.readablePages || null;
    let numPageImagesPreloaded = state.numPageImagesPreloaded || null;
    let isSmartPanelingOn = state.isSmartPanelingOn || null;
    let isInZoomMode = state.isInZoomMode || null;

    const _updatePageInfo = ( pageNum, direction = 'forwards', curPanelNum = 0 ) => {
        let curPageInfo = null;
        let prevPageInfo = null;
        let numPagesAvailable = 0;
        let totalPagesInBook = 0;
        let numPanelsOnPage = 0;
        let curPageNum = pageNum;
        let prevPageNum = ( state.curPageNum !== pageNum ) ? state.curPageNum : state.prevPageNum;
        let displayPageNum = pageNum + 1;
        let curPanels = null;
        let prevPanelNum = state.curPanelNum;

        if( state.curBook && state.curBook.pages && state.curBook.pages.length ) {
            numPagesAvailable = state.curBook.numberOfPages;
            totalPagesInBook = state.curBook.pages.length;
            curPageInfo = state.curBook.pages[ curPageNum ];
            if( curPageInfo && curPageInfo.panels && curPageInfo.panels.length ) {
                curPanels = curPageInfo.panels;
                numPanelsOnPage = curPageInfo.panels.length;
                if( curPanelNum === 0 && direction === 'backwards' ) {
                    curPanelNum = numPanelsOnPage - 1;
                }
            }
            if( prevPageNum !== null ) {
                prevPageInfo = state.curBook.pages[ prevPageNum ];
            }
        }

        let displayPanelNum = curPanelNum + 1;

        return {
            numPagesAvailable,
            totalPagesInBook,
            displayPageNum,
            curPageNum,
            curPageInfo,
            numPanelsOnPage,
            curPanelNum,
            displayPanelNum,
            curPanels,
            prevPageNum,
            prevPageInfo,
            direction,
            prevPanelNum
        };
    };

    const _checkPageNum = ( pageNum ) => {
        const numPagesAvailable = state.curBook.numberOfPages;
        if( pageNum >= numPagesAvailable ) {
            pageNum = numPagesAvailable - 1;
        } else if( pageNum < 0 || ( pageNum !== 0 && !pageNum ) ) {
            pageNum = 0;
        }
        return pageNum;
    };

    const _checkPanelNum = ( panelNum, pageNum ) => {
        let panelCheck = 0;
        if( state.curBook.pages && ( pageNum || pageNum === 0 ) ) {
            const curPageInfo = state.curBook.pages[ pageNum ];
            if( curPageInfo && curPageInfo.panels ) {
                const numPanelsOnPage = curPageInfo.panels.length;
                if( panelNum >= numPanelsOnPage ) {
                    panelNum = numPanelsOnPage - 1;
                } else if( panelNum < 0 || ( panelNum !== 0 && !panelNum ) ) {
                    panelNum = 0;
                } else {
                    panelCheck = panelNum;
                }
            }
        }
        return panelCheck;
    };

    const _goToPrevPage = () => {
        let newState = {
            ...state
        };

        toPage = newState.curPageNum - 1;
        if(
            newState.numPagesAvailable &&
            toPage >= 0
        ) {
            newState = {
                ...newState,
                ..._updatePageInfo( toPage, 'backwards' ),
                changeType: 'page'
            };
        }

        return newState;
    };

    const _goToNextPage = () => {
        let newState = {
            ...state
        };

        toPage = newState.curPageNum + 1;

        if(
            newState.numPagesAvailable &&
            newState.curPageNum + 1 < newState.numPagesAvailable
        ) {
            newState = {
                ...newState,
                ..._updatePageInfo( toPage ),
                changeType: 'page'
            };
        }
        return newState;
    };

    const _goToPrevPanel = () => {
        let newState = {
            ...state
        };

        fromPanel = newState.curPanelNum;
        toPanel = newState.curPanelNum - 1;
        if(
            newState.numPanelsOnPage &&
            toPanel >= 0
        ) {
            newState = {
                ...newState,
                changeType: 'panel',
                direction: 'backwards',
                prevPanelNum: fromPanel,
                curPanelNum: toPanel,
                displayPanelNum: toPanel + 1
            };
        }
        return newState;
    };

    const _goToNextPanel = () => {
        let newState = {
            ...state
        };
        fromPanel = newState.curPanelNum;
        toPanel = newState.curPanelNum + 1;
        if(
            newState.numPanelsOnPage &&
            toPanel < newState.numPanelsOnPage
        ) {
            newState = {
                ...newState,
                changeType: 'panel',
                direction: 'forwards',
                prevPanelNum: fromPanel,
                curPanelNum: toPanel,
                displayPanelNum: toPanel + 1
            };
        }

        return newState;
    };

    switch( action.type ) {
        // PAGE CHANGE ACTIONS
        case printReaderActions.UPDATE_PAGE_INFO:
            toPage = _checkPageNum( action.payload.curPageNum );
            toPanel = _checkPanelNum( action.payload.curPanelNum, toPage );
            state = {
                ...state,
                ..._updatePageInfo( toPage, 'direct', toPanel )
            };
            break;

        case printReaderActions.GO_TO_PAGE_BY_NUMBER:
            toPage = action.payload.toPage;
            if(
                state.numPagesAvailable &&
                toPage >= 0 &&
                toPage <= state.numPagesAvailable
            ) {
                state = {
                    ...state,
                    ..._updatePageInfo( toPage, 'direct' ),
                    changeType: 'direct',
                    direction: 'direct'
                };
            }
            break;
        case printReaderActions.GO_TO_NEXT_PAGE:
            state = _goToNextPage();
            break;
        case printReaderActions.GO_TO_PREV_PAGE:
            state = _goToPrevPage();
            break;
        case printReaderActions.OPEN_ENDSCREEN:
            state = {
                ...state,
                isAtEndScreen: true,
                isHeaderLocked: true,
                isFooterAvailable: false
            };
            break;
        case printReaderActions.CLOSE_ENDSCREEN:
            state = {
                ...state,
                isAtEndScreen: false,
                isHeaderLocked: false,
                isFooterAvailable: true
            };
            break;

        case printReaderActions.MOVE_BACKWARD_IN_BOOK:
            if(
                !state.isAnimating &&
                !state.isAtEndScreen
            ) {
                let toPage = state.curPageNum - 1;
                let toPanel = state.curPanelNum - 1;

                if(
                    state.isSmartPanelingOn &&
                    toPanel >= 0
                ) {
                    state = _goToPrevPanel();
                } else if(
                    toPage >= 0
                ) {
                    state = _goToPrevPage();
                }
            } else if (
                !state.isAnimating &&
                state.isAtEndScreen
            ) {
                state = {
                    ...state,
                    isAtEndScreen: false
                };
            }
            break;
        case printReaderActions.MOVE_FORWARD_IN_BOOK:
            if(
                !state.isAnimating &&
                !state.isAtEndScreen
            ) {
                let toPage = state.curPageNum + 1;
                let toPanel = state.curPanelNum + 1;

                if (
                    state.isSmartPanelingOn &&
                    toPanel < state.numPanelsOnPage
                ) {
                    state = _goToNextPanel();
                } else if(
                    toPage < state.numPagesAvailable
                ) {
                    state = _goToNextPage();
                } else {
                    state = {
                        ...state,
                        isAtEndScreen: true
                    };
                }
            }
            break;

        case printReaderActions.TOGGLE_ALL_PAGES_OPEN:
            state = {
                ...state,
                isAllPagesOpen: !state.isAllPagesOpen,
                isTooltipsOpen: false,
                isBookInfoOpen: false,
                isAtEndScreen: false,
                isHeaderLocked: !state.isAllPagesOpen,
                isFooterAvailable: state.isAllPagesOpen
            };
            break;
        case printReaderActions.TOGGLE_TOOLTIPS_OPEN:
            state = {
                ...state,
                isTooltipsOpen: !state.isTooltipsOpen,
                isAllPagesOpen: false,
                isBookInfoOpen: false,
                isFooterLocked: !state.isTooltipsOpen,
                isHeaderLocked: !state.isTooltipsOpen,
                isFooterAvailable: true
            };
            break;
        case printReaderActions.TOGGLE_BOOK_INFO_OPEN:
            state = {
                ...state,
                isBookInfoOpen: !state.isBookInfoOpen,
                isAllPagesOpen: false,
                isTooltipsOpen: false,
                isAtEndScreen: false,
                isHeaderLocked: !state.isBookInfoOpen,
                isFooterAvailable: true
            };
            break;

            // PANEL CHANGE ACTIONS
        case printReaderActions.GO_TO_PANEL_BY_NUMBER:
            fromPanel = state.curPanelNum;
            toPanel = action.payload;
            if(
                state.numPanelsOnPage &&
                toPanel > 0 &&
                toPanel <= state.numPanelsOnPage
            ) {
                state = {
                    ...state,
                    prevPanelNum: fromPanel,
                    curPanelNum: toPanel,
                    displayPanelNum: toPanel + 1,
                    direction: 'direct',
                    changeType: 'direct'
                };
            }
            break;
        case printReaderActions.GO_TO_NEXT_PANEL:
            state = _goToNextPanel();
            break;
        case printReaderActions.GO_TO_PREV_PANEL:
            state = _goToPrevPanel();
            break;
        case printReaderActions.START_BOOK_ANIMATION:
            state = {
                ...state,
                isAnimating: true
            };
            break;
        case printReaderActions.END_BOOK_ANIMATION:
            state = {
                ...state,
                isAnimating: false,
                direction: null,
                changeType: null
            };
            break;

        case printReaderActions.TOGGLE_PANELING:
            if(
                !state.isAnimating &&
                !state.isAtEndScreen &&
                state.curBook.issue_meta.format !== "Infinite Comic"
            ) {
                isSmartPanelingOn = !state.isSmartPanelingOn;
                isInZoomMode = ( isSmartPanelingOn ) ? false : state.isInZoomMode;
                state = {
                    ...state,
                    isSmartPanelingOn,
                    isInZoomMode,
                    doubleTap: action.payload.doubleTap
                };
            }
            break;
        case printReaderActions.TOGGLE_ZOOM_MODE:
            if(
                !state.isAnimating &&
                !state.isAtEndScreen
            ) {
                isInZoomMode = ( state.isSmartPanelingOn ) ? false : !state.isInZoomMode;
                state = {
                    ...state,
                    isInZoomMode
                };
            }
            break;

            // Preload logic
        case printReaderActions.IMAGE_PRELOAD_STARTED:
            readablePages = state.readablePages || [];
            for( let i = 0; i < action.payload.readablePages.length; i++ ) {
                const page = action.payload.readablePages[ i ];
                readablePages[ i ] = {
                    ...page,
                    didPageImagePreload: false,
                    didPageImageErrorOnPreload: false
                };
            }
            state = {
                ...state,
                readablePages,
                areBookImagesLoading: true
            };
            break;
        case printReaderActions.IMAGE_PRELOAD_ENDED:
            state = {
                ...state,
                areBookImagesLoading: false,
                numPageImagesPreloaded: state.numPagesAvailable,
                isBookReadyToRead: true
            };
            break;
        case printReaderActions.PAGE_IMAGE_PRELOADED:
            readablePages = state.readablePages || [];
            readablePage = readablePages[ action.payload.index ] || {};
            readablePage = {
                ...readablePage,
                didPageImagePreload: true
            };
            numPageImagesPreloaded = state.numPageImagesPreloaded + 1;
            state = {
                ...state,
                readablePages,
                numPageImagesPreloaded,
                isBookReadyToRead: !!( numPageImagesPreloaded >= 3 )
            };
            readablePages[ action.payload.index ] = readablePage;
            break;
        case printReaderActions.PAGE_IMAGE_ERRORED:
            readablePage = state.readablePages[ action.payload.index ] || {};
            readablePage = {
                ...readablePage,
                didPageImageErrorOnPreload: true
            };
            state = {
                ...state,
                numPageImagesPreloaded: state.numPageImagesPreloaded + 1
            };
            state.readablePages[ action.payload.index ] = readablePage;
            break;
        default:
            break;
    }

    return state;
};
