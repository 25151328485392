import React, { useContext, useCallback, useMemo, useEffect } from "react";
import "./vertical-reader.scss";
import PropTypes from "prop-types";
import Header from "./../Header";
import Footer from "./../Footer";
import BookInfo from "../BookInfo";
import InfoTooltips from "../InfoTooltips";
import { VerticalReaderContext } from "../../hooks/verticalReaderContext";
import { verticalReaderActions } from "../../hooks/verticalReaderReducer";
import useViewportSize from "../../hooks/useViewportSize";
import VerticalPanel from "./VerticalPanel";
import LastView from "./../LastView";
import EndOfFeed from "./EndOfFeed";
import PreviewBox from "../PreviewBox";
import get from "lodash/get";
import debounce from "lodash/debounce";
import { getIsFreePromo } from "../../util/helpers";

const VerticalComicReader = (props) => {
  const {
    verticalReaderState,
    verticalReaderDispatch,
    toggleBookInfoOpen,
    toggleTooltipsOpen,
  } = useContext(VerticalReaderContext);
  const {
    curBook,
    isAtEndScreen,
    isNearEndScreen,
    isFooterAvailable,
    isBookInfoOpen,
    isTooltipsOpen,
  } = verticalReaderState;

  const { id, pages, prev_next_issue, discoverData, numberOfPages, is_preview } =
    curBook;

  const isFree = get(curBook, "is_free") || false;
  const isFreePromo = getIsFreePromo(id); 
  const isLoggedIn = get(curBook, "auth_state.logged_in") || false || isFree;
  const isMUUser = get(curBook, "auth_state.subscriber") || false;
  const panels = pages.slice(0, numberOfPages);

  const { viewportWidth } = useViewportSize();
  const readerWidth = viewportWidth < 800 ? viewportWidth : 800;
  const nextIssueMeta = useMemo(
    () => get(prev_next_issue, "next_issue_meta"),
    [prev_next_issue]
  );
  const _calculateHeight = useCallback(() => {
    if (panels) {
      let height = 0;
      for (let i = 0; i < panels.length; i++) {
        const panel = panels[i];
        if (panel.height) {
          const ratio = readerWidth / panel.width;
          height += panel.height * ratio;
        }
      }
      return height;
    }
  }, [panels, readerWidth]);

  const style = useMemo(() => {
    const _style = isAtEndScreen
      ? { height: "0", overflow: "hidden" }
      : { minHeight: _calculateHeight() };

    return _style;
  }, [_calculateHeight, isAtEndScreen]);

  useEffect(() => {
    window.onscroll = debounce((ev) => {
      const scrollToPageBottom =
        window.scrollY + window.innerHeight + 20 >= document.body.offsetHeight;
      const nearPageBottom =
        window.scrollY > document.body.offsetHeight - (3 * window.innerHeight);

      if (nearPageBottom) {
        verticalReaderDispatch({
          type: verticalReaderActions.OPEN_ENDOFFEED,
        });
      } else {
        verticalReaderDispatch({
          type: verticalReaderActions.CLOSE_ENDOFFEED,
        });
      }
      if (scrollToPageBottom) {
        verticalReaderDispatch({
          type: verticalReaderActions.OPEN_ENDSCREEN,
        });
      }
    }, 100);

    return () => {
      window.scroll = null;
    };
  }, [verticalReaderDispatch]);

  const panelEls = useMemo(() => {
    const panelEls = [];
    for (let i = 0; i < panels.length; i++) {
      const panel = panels[i];
      if (panel) {
        const ratio = readerWidth / panel.width;
        panelEls.push(
          <VerticalPanel
            panelNum={i}
            key={`panel-${i}`}
            src={panel.url}
            alt={`panel ${i + 1}`}
            width={readerWidth}
            height={panel.height * ratio}
          />
        );
      }
    }
    return panelEls;
  }, [panels, readerWidth]);

  const thumbnail = useMemo(
    () => get(curBook, "issue_meta.thumbnail"),
    [curBook]
  );
  const creators = useMemo(
    () => get(curBook, "issue_meta.creators"),
    [curBook]
  );
  const features = useMemo(
    () => get(curBook, "issue_meta.features"),
    [curBook]
  );
  // const next_issue_meta = useMemo( () => get( curBook, 'prev_next_issue.next_issue_meta' ), [ curBook ] );

  return (
    <section className="vertical-reader">
      <div className="panel-feed" style={style}>
        {panelEls}
      </div>
      {is_preview && !isAtEndScreen && <PreviewBox isLoggedIn={isLoggedIn} />}
      <EndOfFeed
        nextIssueMeta={nextIssueMeta}
        isMUUser={isMUUser}
        discoverData={discoverData}
        endOfFeedClass={isNearEndScreen ? "show" : ""}
        isFree={isFree}
        isFreePromo={isFreePromo}
      />
      {isAtEndScreen && (
        <LastView
          nextIssueMeta={nextIssueMeta}
          isMUUser={isMUUser}
          isLoggedIn={isLoggedIn}
          discoverData={discoverData}
          readerContext={VerticalReaderContext}
          isFree={isFree}
          isFreePromo={isFreePromo}
        />
      )}
      {!isAtEndScreen &&
        <Header
          readerContext={VerticalReaderContext}
          readerState={verticalReaderState}
          headerClass="infinite-comic"
        />
      }
      {isFooterAvailable && !isAtEndScreen && !isNearEndScreen && (
        <Footer
          readerContext={VerticalReaderContext}
          readerState={verticalReaderState}
          footerClass="infinite-comic"
        />
      )}
      {isTooltipsOpen && (
        <InfoTooltips
          toggleTooltipsOpen={toggleTooltipsOpen}
          tooltipsClass="infinite-comic"
          readerState={verticalReaderState}
        />
      )}
      {isBookInfoOpen && (
        <BookInfo
          thumbnail={thumbnail}
          title={get(curBook, "issue_meta.title")}
          releaseDateFormatted={get(
            curBook,
            "issue_meta.release_date_formatted"
          )}
          releaseDateDigitalFormatted={get(
            curBook,
            "issue_meta.release_date_digital_formatted"
          )}
          rating={get(curBook, "issue_meta.rating")}
          creators={creators}
          imprint={get(curBook, "issue_meta.imprint")}
          format={get(curBook, "issue_meta.reader_format")}
          features={features}
          description={get(curBook, "issue_meta.description")}
          toggleBookInfoOpen={toggleBookInfoOpen}
        />
      )}
    </section>
  );
};

VerticalComicReader.propTypes = {
  curBook: PropTypes.object,
};

export default VerticalComicReader;
