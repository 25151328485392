import React from 'react';
import PropTypes from 'prop-types';

const PageImage = ( props ) => {
    return (
        <image
            id={props.id}
            className="pageImage"
            width={props.width}
            height={props.height}
            clipPath={`url('${ props.clipPathSelector }')`}
            xlinkHref={props.imageURL}
        />
    );
};

PageImage.propTypes = {
    id: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    clipPathSelector: PropTypes.string,
    imageURL: PropTypes.string
};

export default PageImage;