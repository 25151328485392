import React, { createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    appReducer,
    initialAppState
} from "./appReducer";
import { setInitialDataLayerState } from "./uatu";
import useIdleTimeoutStatus from "./useIdleTimeoutStatus";

export const AppContext = createContext();

export const AppProvider = ( props ) => {
    const [ appState, appDispatch ] = useReducer(
        appReducer,
        {
            ...initialAppState
        }
    );

    const idleTimeoutStatus = useIdleTimeoutStatus();


    useEffect(() => {
        setInitialDataLayerState();
    }, []);
    return (
        <AppContext.Provider
            value = {{
                appState,
                appDispatch,
                idleTimeoutStatus
            }}
        >
            { props.children }
        </AppContext.Provider>
    );
};
AppProvider.propTypes = {
    children: PropTypes.element.isRequired
};
