import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const useFullscreenStatus = () => {
    const [ isFullscreen, setFullScreen ] = useState( false );

    const checkFS = () => {
        setFullScreen((document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement ||
            document.mozFullScreen ||
            document.webkitIsFullScreen ||
            document.fullScreenMode) !== undefined);
    };

    useEffect(() => {
        checkFS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document.fullscreenElement,
        document.mozFullScreenElement,
        document.webkitFullscreenElementfullscreenElement,
        document.msFullscreenElementfullscreenElement,
        document.mozFullScreenfullscreenElement,
        document.webkitIsFullScreenfullscreenElement,
        document.fullScreenMode]);

    const enterFullscreen = useCallback(() => {
        if( document?.documentElement?.requestFullscreen ) {
            const _scrollY = window.scrollY; // This is for safari
            document.documentElement.requestFullscreen();
            setTimeout(() => window.scrollTo(0, _scrollY), 200); // This is for safari
        } else if ( document?.documentElement?.mozRequestFullScreen ) {
            document.documentElement.mozRequestFullScreen();
        } else if ( document?.documentElement?.webkitRequestFullscreen ) {
            document.documentElement.webkitRequestFullscreen();
        } else if ( document?.documentElement?.msRequestFullscreen ) {
            document.documentElement.msRequestFullscreen();
        }
        setTimeout(checkFS, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const exitFullscreen = useCallback(() => {
        if( document?.exitFullscreen ) {
            const _scrollY = window.scrollY; // This is for safari 16+
            document.exitFullscreen();
            setTimeout(() => window.scrollTo(0, _scrollY), 1000); // This is for safari 16+
        } else if( document?.mozCancelFullScreen ) {
            document.mozCancelFullScreen();
        } else if( document?.webkitExitFullscreen ) {
            document.webkitExitFullscreen();
        } else if( document?.msExitFullscreen ) {
            document.msExitFullscreen();
        }
        setTimeout(checkFS, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleFullscreen = () => {
        if( isFullscreen ) {
            exitFullscreen();
        } else {
            enterFullscreen();
        }
    };

    return ({
        toggleFullscreen,
        isFullscreen
    });
};

useFullscreenStatus.propTypes = {
    isFullscreen: PropTypes.bool,
    readerState: PropTypes.object
};

export default useFullscreenStatus;