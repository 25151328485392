export const verticalReaderActions = {
    // UPDATE_PAGE_INFO: "updatePageInfo",
    // GO_TO_PAGE_BY_NUMBER: "goToPageByNumber",
    // GO_TO_NEXT_PAGE: "goToNextPage",
    // GO_TO_PREV_PAGE: "goToPrevPage",

    // GO_TO_PANEL_BY_NUMBER: "goToPanelByNumber",
    // GO_TO_NEXT_PANEL: "goToNextPanel",
    // GO_TO_PREV_PANEL: "goToPrevPanel",

    // MOVE_FORWARD_IN_BOOK: "moveForwardInBook",
    // MOVE_BACKWARD_IN_BOOK: "moveBackwardInBook",

    OPEN_ENDSCREEN: "openEndScreen",
    CLOSE_ENDSCREEN: "closeEndScreen",
    SCROLL_PROGRESS: "scrollProgress",
    OPEN_ENDOFFEED: "openEndOfFeed",
    CLOSE_ENDOFFEED: "closeEndOfFeed",
    // START_BOOK_ANIMATION: "startAnimation",
    // END_BOOK_ANIMATION: "endAnimation",
    // ANIMATE: "animate",
    // IMAGE_PRELOAD_STARTED: "imagePreloadStarted",
    // IMAGE_PRELOAD_ENDED: "imagePreloadEnded",
    // PAGE_IMAGE_PRELOADED: "pageImagePreloaded",
    // PAGE_IMAGE_ERRORED: "pageImageErroed",

    // TOGGLE_PANELING: "togglePaneling",
    // TOGGLE_ZOOM_MODE: "toggleZoomMode",
    // TOGGLE_FULLSCREEN: "toggleFullscreen",
    // TOGGLE_ALL_PAGES_OPEN: "toggleAllPagesOpen",
    TOGGLE_TOOLTIPS_OPEN: "toggleTooltipsOpen",
    TOGGLE_BOOK_INFO_OPEN: "toggleBookInfoOpen",
};

export const initialVerticalReaderState = {
    displayPageNum: 0,
    totalPagesInBook: 0,
    curPageInfo: null,
    curBook: null,
    isAtEndScreen: false,
    isNearEndScreen: false,
    panelLoaded: 0,
    isFooterAvailable: true,
    isAllPagesOpen: false,
    numPagesAvailable: 0,
    curPageNum: 0,
    numPageImagesPreloaded: 0,
    isHeaderLocked: false,
    isBookInfoOpen: false,
    isTooltipsOpen: false,
};

export const verticalReaderReducer = (state, action) => {
    const _updatePageInfo = (
        pageNum,
        direction = "forwards",
        curPanelNum = 0
    ) => {
        let curPageInfo = null;
        let prevPageInfo = null;
        let numPagesAvailable = 0;
        let totalPagesInBook = 0;
        let numPanelsOnPage = 0;
        let curPageNum = pageNum;
        let prevPageNum = state.curPageNum !== pageNum ? state.curPageNum : state.prevPageNum;
        let displayPageNum = pageNum;
        let curPanels = null;
        let prevPanelNum = state.curPanelNum;

        if (state.curBook && state.curBook.pages && state.curBook.pages.length) {
            numPagesAvailable = state.curBook.numberOfPages;
            totalPagesInBook = state.curBook.pages.length;
            curPageInfo = state.curBook.pages[curPageNum];
            if (curPageInfo && curPageInfo.panels && curPageInfo.panels.length) {
                curPanels = curPageInfo.panels;
                numPanelsOnPage = curPageInfo.panels.length;
                if (curPanelNum === 0 && direction === "backwards") {
                    curPanelNum = numPanelsOnPage - 1;
                }
            }
            if (prevPageNum !== null) {
                prevPageInfo = state.curBook.pages[prevPageNum];
            }
        }

        let displayPanelNum = curPanelNum + 1;

        return {
            numPagesAvailable,
            totalPagesInBook,
            displayPageNum,
            curPageNum,
            curPageInfo,
            numPanelsOnPage,
            curPanelNum,
            displayPanelNum,
            curPanels,
            prevPageNum,
            prevPageInfo,
            direction,
            prevPanelNum,
        };
    };

    switch (action.type) {
        // PAGE CHANGE ACTIONS
        case verticalReaderActions.TOGGLE_BOOK_INFO_OPEN:
            state = {
                ...state,
                isBookInfoOpen: !state.isBookInfoOpen,
                isAllPagesOpen: false,
                isTooltipsOpen: false,
                isAtEndScreen: false,
                isHeaderLocked: !state.isBookInfoOpen,
                isFooterAvailable: true,
            };
            break;
        case verticalReaderActions.TOGGLE_TOOLTIPS_OPEN:
            state = {
                ...state,
                isTooltipsOpen: !state.isTooltipsOpen,
                isAllPagesOpen: false,
                isBookInfoOpen: false,
                isFooterLocked: !state.isTooltipsOpen,
                isHeaderLocked: !state.isTooltipsOpen,
                isFooterAvailable: true,
            };
            break;
        case verticalReaderActions.SCROLL_PROGRESS: {
            if (action.payload.panelLoaded > state.displayPageNum) {
                state = {
                    ...state,
                    ..._updatePageInfo(action.payload.panelLoaded),
                };
            }
            break;
        }
        case verticalReaderActions.OPEN_ENDOFFEED:
            state = {
                ...state,
                isNearEndScreen: true,
            };
            break;
        case verticalReaderActions.CLOSE_ENDOFFEED:
                state = {
                    ...state,
                    isNearEndScreen: false,
                };
                break;
        case verticalReaderActions.OPEN_ENDSCREEN:
            state = {
                ...state,
                isAtEndScreen: true,
                isHeaderLocked: true,
                isFooterAvailable: false,
                isNearEndScreen: false,
            };
            break;
        case verticalReaderActions.CLOSE_ENDSCREEN:
            state = {
                ...state,
                isAtEndScreen: false,
                isHeaderLocked: false,
                isFooterAvailable: true,
            };
            break;
        default:
            break;
    }

    return state;
};
