import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isString, isObject } from 'lodash';

const Fields = ( props ) => {
    const {
        data,
        str,
        cssClass
    } = props;

    const el = useMemo(() => {
        let el = null;
        if( isString( data ) ) {
            el = (
                <p className={cssClass}>
                    <span>{str}:</span> {data}
                </p>
            );
        } else if( isObject( data ) && data.extended_list_roles ) {
            el = [];
            for( let num = 0; num < data.extended_list_roles.length; num++ ) {
                const val = data.extended_list_roles[num];
                let name = '';
                let i = 0;
                for( let index = 0; index < data.extended_list.length; index++ ) {
                    const obj = data.extended_list[index];
                    if( val === obj.role ) {
                        if( i >= 1 ) {
                            name += ', ' + obj.full_name;
                        } else {
                            name += obj.full_name;
                        }
                        i++;
                    }
                }

                el.push(
                    <p key = { `list-${str}-${num}`}>
                        <span>{ val }:</span> { name }
                    </p>
                );
            }
        }
        return el;
    }, [
        data,
        str,
        cssClass
    ]);

    return el;
};

Fields.propTypes = {
    data: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    str: PropTypes.string,
    cssClass: PropTypes.string
};

export default Fields;