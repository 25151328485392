import React from 'react';
import PropTypes from 'prop-types';
import hydraImage from '../images/hydra-404_360.png';

const errorDetails = {
    '404': {
        header: '404: Issue not found',
        messageHeadline: '404: Issue Stolen by Hydra',
        message: 'This issue could not be found. It seems to have been stolen by HYDRA forces. If you&#8217;d like to report this error to S.H.I.E.L.D, please submit a ticket via <a href="https://help.marvel.com" target="_blank">Help.Marvel.com</a>.'
    },
    '500': {
        header: '500: Gone horribly wrong',
        messageHeadline: '500: Gone horribly wrong',
        message: 'That didn&#8217;t go well. Try refreshing the page or go back to <a href="http://marvel.com">Marvel.com</a>. If you keep seeing this error, let us know by submitting a ticket via <a href="https://help.marvel.com" target="_blank">Help.Marvel.com</a>.'
    },
    '401': {
        header: '401: Unauthorized',
        messageHeadline: '401: Unauthorized',
        message: 'You are not authorized to view this page. ' +
            'Please contact whomever directed you here for more information.'
    }
};

const ErrorPage = ( props ) => {
    const errorDetail = ( errorDetails[ props.errorCode ] ) ?
        errorDetails[ props.errorCode ] :
        errorDetails[ "404" ];

    return (
        <div
            className = "error-page"
        >
            <section id = "errorpage">
                <header>
                    <h3>{ errorDetail.header }</h3>
                    <div></div>
                </header>
                <div className="message-container">
                    <img src={ hydraImage } alt="404" />
                    <div className="message">
                        <div className="headline">{ errorDetail.messageHeadline }</div>
                        <div className="words"
                            dangerouslySetInnerHTML= {{
                                __html: errorDetail.message
                            }}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};

ErrorPage.propTypes = {
    errorCode: PropTypes.number
};

export default ErrorPage;