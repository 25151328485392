import React from 'react';
import PropTypes from 'prop-types';
import Fields from './Fields';

const BookInfo = ( props ) => {
    return (
        <div id="overlayRelativer">
            <div className="_DPOP_mask _DPOP_hasMask"></div>
            <div id="dPopWin_wrap" className="_DPOP_window_wrap bringDPopUpIn">
                <div
                    id="dPopWin"
                    className="aPawInMyFace _DPOP_window dPop_isFixed"
                    style={{zIndex: 1000000000}}
                >
                    <div
                        className="dPopClose"
                        onClick={ props.toggleBookInfoOpen }
                        role="button"
                        tabIndex="0"
                    >
                        <span className="icon-close" />
                    </div>
                    {
                        props.thumbnail &&
                        <div className="thumbnail">
                            <img
                                src={ props.thumbnail.path + '/portrait_fantastic.jpg' }
                                alt= { props.title }
                            />
                        </div>
                    }
                    <div className="content">
                        <h1>
                            { props.title }
                        </h1>
                        <article>
                            <Fields
                                data = { props.releaseDateFormatted }
                                str = "Published"
                            />

                            <Fields
                                data = { props.releaseDateDigitalFormatted }
                                str = "Added to Marvel Unlimited"
                            />

                            <Fields
                                data = { props.rating }
                                str = "Rating"
                            />

                            <Fields
                                data = { props.creators }
                            />

                            <Fields
                                data = { props.imprint }
                                str = "Imprint"
                            />

                            <Fields
                                data = { props.format === "Digital Vertical Comic" ? "Infinity Comic" : props.format }
                                str = "Format"
                            />

                            <Fields
                                data = { props.features }
                            />

                            <Fields
                                data = { props.description }
                                str = "Description"
                                cssClass = "desc"
                            />
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
};

BookInfo.propTypes = {
    thumbnail: PropTypes.object,
    title: PropTypes.string,
    releaseDateFormatted: PropTypes.string,
    releaseDateDigitalFormatted: PropTypes.string,
    rating: PropTypes.string,
    creators: PropTypes.object,
    imprint: PropTypes.string,
    format: PropTypes.string,
    features: PropTypes.object,
    description: PropTypes.string,
    toggleBookInfoOpen: PropTypes.func
};

export default BookInfo;