import React from 'react';
import PropTypes from 'prop-types';
import NoBook from '../NoBook';
import NextBook from '../NextBook';
import SpotlightSection from '../Spotlight/SpotlightSection';
import './end-of-feed.scss';

const EndOfFeed = ( props ) => {
    const {
        nextIssueMeta,
        isMUUser,
        discoverData,
        isFree,
        isFreePromo
    } = props;

    let content;

    if( nextIssueMeta && !isFreePromo ) {    
        content = (
            <NextBook
                thumbnail = { nextIssueMeta.thumbnail }
                creators = { nextIssueMeta.creators }
                title = { nextIssueMeta.title }
                id = { nextIssueMeta.id }
                releaseDateFormatted = { nextIssueMeta.release_date_digital_formatted }
                releaseDateDigitalFormatted = { nextIssueMeta.release_date_formatted }
                rating = { nextIssueMeta.rating }
                imprint = { nextIssueMeta.imprint }
                format = { nextIssueMeta.format }
                features = { nextIssueMeta.features }
                isFree={ isFree }
                isMUUser={ isMUUser }
            />
        );

    } else {
        content = (
            <NoBook
                isFreePromo={ true }
                isMUUser={ isMUUser }
            />
        );
    }

    return (
        <section
            id = "end_of_feed"
            className = {`end-of-feed ${props.endOfFeedClass}`}
        >

            { content }

            {
                discoverData?.length &&
                    <SpotlightSection
                        key = "spotlight"
                        discoverData = { discoverData }
                    />
            }
        </section>
  );
};

EndOfFeed.propTypes = {
    nextIssueMeta: PropTypes.object,
    isMUUser: PropTypes.bool, 
    discoverData: PropTypes.array,
    endOfFeedClass: PropTypes.string,
    isFree: PropTypes.bool,
    isFreePromo: PropTypes.bool
};

export default EndOfFeed;
