import { useEffect, useState, useContext, useCallback } from 'react';
import { getTransitionEndEventName } from "../util/helpers";
import { uatuActions, manageDataLayer } from './uatu';
import delay from 'lodash/delay';

const useLastViewStatus = ( props ) => {
    const [ curtainClassNameMods, setCurtainClassNameMods ] = useState( '' );
    const [ lastViewContainerClassNameMods, setLastViewContainerClassNameMods ] = useState( '' );
    const [ animationType, setAnimationType ] = useState( null );
    const transitionName = getTransitionEndEventName();
    const { closeEndScreen } = useContext( props.readerContext );

    const _onCurtainTransition = useCallback(( e ) => {
        if( animationType === 'open' ) {
            setLastViewContainerClassNameMods( ' animate' );
        } else if( animationType === 'close' ) {
            setAnimationType( null );
            delay( closeEndScreen, 200 );
        }
    }, [ animationType, closeEndScreen ]);

    const _onLastViewContainerTransition = useCallback(( e ) => {
        if( animationType === 'open' ) {
            setAnimationType( null );
        } else if( animationType === 'close' ) {
            setCurtainClassNameMods( ' ' );
        }
    }, [ animationType ]);

    const closeLastView = useCallback(() => {
        setAnimationType( 'close' );
        manageDataLayer({
            type: uatuActions.REPORT_END_SCREEN_CLOSE,
        });
    },[]);

    useEffect(() => {
        if( animationType === 'open' ) {
            setCurtainClassNameMods( ' active' );
        } else if( animationType === 'close' ) {
            setLastViewContainerClassNameMods( ' ' );
        }
    }, [ animationType ]);

    useEffect(() => {
        delay( () => setAnimationType( 'open' ), 200 );
    }, []);

    useEffect(() => {
        props.curtainRef.current.addEventListener( transitionName, _onCurtainTransition, true );
        return () => {
            if(props.lastViewContainerRef.current) {
                props.curtainRef.current.removeEventListener( transitionName, _onCurtainTransition, true );
            }
        };
    }, [ _onCurtainTransition, transitionName, props.curtainRef, props.lastViewContainerRef ]);

    useEffect(() => {
        props.lastViewContainerRef.current.addEventListener( transitionName, _onLastViewContainerTransition, true );
        return () => {
            if(props.lastViewContainerRef.current) {
                props.lastViewContainerRef.current.removeEventListener( transitionName, _onLastViewContainerTransition, true );
            }
        };
    }, [ _onLastViewContainerTransition, transitionName, props.curtainRef, props.lastViewContainerRef ]);

    return {
        curtainClassNameMods,
        lastViewContainerClassNameMods,
        closeLastView
    };
};

export default useLastViewStatus;