import React from 'react';
import PropTypes from 'prop-types';
import BuyPage from './BuyPage';

const NoPreviewBuyPage = ( props ) => {
    return (
        <div
            id = "last_view"
            className ="last_page no-preview preview-buypage animate"
        >
            <BuyPage
                isLoggedIn = { props.isLoggedIn }
                isMUUser = { props.isMUUser }
            />
        </div>
    );
};

NoPreviewBuyPage.propTypes = {
    isLoggedIn: PropTypes.bool,
    isMUUser: PropTypes.bool
};

export default NoPreviewBuyPage;