/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react';

const useZoomStatus = ( props ) => {
    const [ isGrabbing, setIsGrabbing ] = useState( false );
    const [ isPanning, setIsPanning ] = useState( false );
    const [ x, setX ] = useState( 0 );
    const [ y, setY ] = useState( 0 );
    const [ startCursorX, setStartCursorX ] = useState( null );
    const [ startCursorY, setStartCursorY ] = useState( null );
    const [ startLeft, setStartLeft] = useState( null );
    const [ startTop, setStartTop ] = useState( null );
    const [ isActive, setIsActive ] = useState( false );

    const {
        pageHolderRef,
        printReaderState
    } = props;

    const {
        isInZoomMode,
        isAnimating
    } = printReaderState;

    const _onMouseMove = useCallback(( e ) => {
        if( isGrabbing ) {
            const moveX = e.pageX;
            const moveY = e.pageY;
            let newX = startCursorX - moveX + startLeft;
            let newY = startCursorY - moveY + startTop;
            newX = ( newX < 0 ) ? 0 : newX;
            newX = ( newX > pageHolderRef.current.scrollLeftMax ) ? pageHolderRef.current.scrollLeftMax : newX;
            newY = ( newY < 0 ) ? 0 : newY;
            newY = ( newY > pageHolderRef.current.scrollTopMax ) ? pageHolderRef.current.scrollTopMax : newY;

            setX( newX );
            setY( newY );

            if( startCursorX !== newX || startCursorY !== newY ) {
                setIsPanning( true );
            }
        }
    }, [
        pageHolderRef,
        startCursorX,
        startCursorY,
        isGrabbing,
        startLeft,
        startTop
    ]);

    const _onMouseDown = useCallback(( e ) => {
        setStartCursorX( e.pageX );
        setStartCursorY( e.pageY );
        setStartLeft(pageHolderRef.current.scrollLeft);
        setStartTop(pageHolderRef.current.scrollTop);

        setIsGrabbing( true );
    }, [
        pageHolderRef
    ]);

    const _onMouseUpAndLeave = useCallback(( e ) => {
        setIsGrabbing( false );
        setIsPanning( false );
        setStartCursorX( null );
        setStartCursorY( null );
        setStartLeft( null );
        setStartTop( null );
    }, []);

    const _deactivate = useCallback(() => {
        if( isActive ) {
            pageHolderRef.current.onmousedown = null;
            pageHolderRef.current.onmouseup = null;
            pageHolderRef.current.onmouseleave = null;
            pageHolderRef.current.onmousemove = null;
            setX( 0 );
            setY( 0 );
            setIsGrabbing( false );
            setIsPanning( false );
            setStartCursorX( null );
            setStartCursorY( null );
            setStartLeft( null );
            setStartTop( null );
            setIsActive( false );
        }
    }, [ isActive ]);

    const _activate = useCallback(() => {
        if( !isActive ) {
            pageHolderRef.current.onmousedown = _onMouseDown;
            pageHolderRef.current.onmouseup = _onMouseUpAndLeave;
            pageHolderRef.current.onmouseleave = _onMouseUpAndLeave;
            setIsActive( true );
        }
    }, [ isActive ]);

    useEffect(() => {
        if(
            isInZoomMode &&
            pageHolderRef &&
            pageHolderRef.current &&
            !isAnimating
        ) {
            _activate();
        } else if(
            !isInZoomMode &&
            pageHolderRef &&
            pageHolderRef.current
        ) {
            _deactivate();
        } else if( isAnimating ) {
            _deactivate();
        }
    }, [
        isInZoomMode,
        isAnimating,
        pageHolderRef
    ] );

    useEffect(() => {
        if( isAnimating ) {
            _deactivate();
        }
    }, [
        isAnimating,
        _deactivate
    ]);

    useEffect(() => {
        if( pageHolderRef && pageHolderRef.current ) {
            if( isGrabbing ) {
                pageHolderRef.current.onmousemove = _onMouseMove;
            } else {
                pageHolderRef.current.onmousemove = null;
            }
        }
    }, [
        isGrabbing
    ]);

    useEffect(() => {
        if( pageHolderRef && pageHolderRef.current ) {
            pageHolderRef.current.scrollTo(x,y);
        }
    }, [
        x,
        y
    ]);


    return({
        isGrabbing,
        isPanning
    });
};

export default useZoomStatus;