import { useState, useEffect, useCallback } from 'react';
import throttle from 'lodash/throttle';

let timer = null;

const clickZonePercentage = 15;

const useIdleTimeoutStatus = () => {
    const [ isIdle, setIsIdle ] = useState( false );

    const _onInactivity = useCallback(() => {
        setIsIdle( true );
    }, []);

    const _onActivity = useCallback(throttle(( e ) => {
        // PROTECTED AREA FOR NEXT/PREV ACTIONS
        const width = window.innerWidth;
        const leftArea = ( width * clickZonePercentage ) / 100;
        const rightArea = width - leftArea;
        const isProtectedClick = !!( e.pageX && ( e.pageX >= rightArea || e.pageX <= leftArea ) );

        if( !isProtectedClick ) {
            if( timer !== null ) {
                clearTimeout( timer );
            }
            timer = setTimeout( () => _onInactivity(), 3000 );
            setIsIdle( false );
        }
    }, 500 ), [
        _onInactivity
    ]);

    useEffect(() => {
        ['click'].forEach((e) => {
            window.addEventListener(e, _onActivity);
        });

        return () => {
            [ 'click'].forEach((e) => {
                window.removeEventListener(e, _onActivity);
            });
        };
    }, [ _onActivity ]);

    useEffect(() => {
        timer = setTimeout( () => _onInactivity(), 3000 );
    }, [ _onInactivity ]);

    return {
        isIdle
    };
};

export default useIdleTimeoutStatus;