import { useState, useCallback } from 'react';
import { easing } from "../util/helpers";
import { printReaderActions } from "./printReaderReducer";

const positionValues = [ 'x', 'y', 'width', 'height', 'containerWidth', 'containerHeight' ];

const usePanelTransitionStatus = ( props ) => {
    const [ x, setX ] = useState( props.x );
    const [ y, setY ] = useState( props.y );
    const [ width, setWidth ] = useState( props.width );
    const [ height, setHeight ] = useState( props.height );
    const [ fromX, setFromX ] = useState( null );
    const [ fromY, setFromY ] = useState( null );
    const [ fromWidth, setFromWidth ] = useState( null );
    const [ fromHeight, setFromHeight ] = useState( null );
    const [ atPanelNum, setAtPanelNum ] = useState( props.atPanelNum );
    const [ alpha, setAlpha ] = useState( 1 );
    const [ maskColor, setMaskColor ] = useState( props.maskColor );
    const [ fromMaskColor, setFromMaskColor ] = useState( null );
    const [ isCrossfading, setIsCrossfading ] = useState( false );
    const [ containerWidth, setContainerWidth ] = useState( props.containerWidth );
    const [ containerHeight, setContainerHeight ] = useState( props.containerHeight );

    const {
        printReaderState,
        panels,
        printReaderDispatch,
        pageRect
    } = props;

    const {
        curPanelNum,
        isAnimating,
    } = printReaderState;

    const setPaningPosition = ( rect ) => {
        setX( rect.x );
        setY( rect.y );
        setWidth( rect.width );
        setHeight( rect.height );
        setMaskColor( rect.maskColor );
    };

    const _setZoomAndPaningPosition = ( rect ) => {
        setX( rect.x );
        setY( rect.y );
        setWidth( rect.width );
        setHeight( rect.height );
        setMaskColor( rect.maskColor );
        setContainerWidth( rect.containerWidth );
        setContainerHeight( rect.containerHeight );
    };

    const _setCrossFadingAlpha = ( alpha, fromRect = null, toRect = null ) => {
        const isCF = !( alpha >= 1 );
        setIsCrossfading( isCF );

        if( fromRect ) {
            setFromX( fromRect.x );
            setFromY( fromRect.y );
            setFromWidth( fromRect.width );
            setFromHeight( fromRect.height );
            setFromMaskColor( fromRect.maskColor );
        } else if( !isCF ) {
            setFromX( null );
            setFromY( null );
            setFromWidth( null );
            setFromHeight( null );
            setFromMaskColor( null );
        }

        if( toRect ) {
            setX( toRect.x );
            setY( toRect.y );
            setWidth( toRect.width );
            setHeight( toRect.height );
            setMaskColor( toRect.maskColor );
        }

        setAlpha( alpha );
    };

    const _setZoomSize = ( containerWidth, containerHeight ) => {
        setContainerWidth( containerWidth );
        setContainerHeight( containerHeight );
    };

    const _panTick = useCallback(( startTime, duration, fromRect, toRect, changeAmounts, isPageExit = false, isZoomExit = false ) => {
        window.requestAnimationFrame(() => {
            const updateTime = Date.now();
            const elapsedTime = updateTime - startTime;

            if( duration >= elapsedTime ) {
                const rect = {};
                for (let i = 0; i < positionValues.length; i++) {
                    const key = positionValues[ i ];
                    if( fromRect[ key ] || fromRect[ key ] === 0 ) {
                        rect[ key ] = easing.easeInOutQuad(
                            elapsedTime,
                            fromRect[ key ],
                            changeAmounts[ key ],
                            duration
                        );
                    }
                }
                if( isZoomExit) {
                    _setZoomAndPaningPosition( rect );
                } else {
                    setPaningPosition( rect );
                }

                _panTick( startTime, duration, fromRect, toRect, changeAmounts, isPageExit, isZoomExit );
            } else {
                const rect = {};
                for (let i = 0; i < positionValues.length; i++) {
                    const key = positionValues[i];
                    if( key === 'containerWidth' || key === 'containerHeight' ) {
                        rect[ key ] = null;
                    } else if( toRect[ key ] || toRect[ key ] === 0 ) {
                        rect[ key ] = toRect[ key ];
                    }
                }
                if( isZoomExit) {
                    _setZoomAndPaningPosition( rect );
                } else {
                    setPaningPosition( rect );
                }
                if( isPageExit ) {
                    setAtPanelNum( 'page' );
                } else {
                    setAtPanelNum( curPanelNum );
                }

                printReaderDispatch({
                    type: printReaderActions.END_BOOK_ANIMATION
                });
            }
        });
    }, [
        curPanelNum,
        printReaderDispatch
    ]);

    const _crossfadeTick = useCallback(( startTime, duration ) => {
        window.requestAnimationFrame(() => {
            const updateTime = Date.now();
            const elapsedTime = updateTime - startTime;
            if( duration >= elapsedTime ) {
                let alpha = easing.easeInOutQuad(
                    elapsedTime,
                    0,
                    1,
                    duration
                );
                alpha = ( alpha > 1 ) ? 1 : alpha;
                _setCrossFadingAlpha( alpha );
                _crossfadeTick( startTime, duration );
            } else {
                _setCrossFadingAlpha( 1 );
                setAtPanelNum( curPanelNum );
                printReaderDispatch({
                    type: printReaderActions.END_BOOK_ANIMATION
                });
            }
        });
    },[
        curPanelNum,
        printReaderDispatch
    ]);

    const _zoomTick = useCallback(( startTime, duration, fromRect, toRect, changeAmounts, isInZoomMode, isSmartPanelingOn ) => {
        window.requestAnimationFrame(() => {
            const updateTime = Date.now();
            const elapsedTime = updateTime - startTime;
            if( duration >= elapsedTime ) {
                let containerWidth = easing.easeInOutQuad(
                    elapsedTime,
                    fromRect.containerWidth,
                    changeAmounts.containerWidth,
                    duration
                );
                let containerHeight = easing.easeInOutQuad(
                    elapsedTime,
                    fromRect.containerHeight,
                    changeAmounts.containerHeight,
                    duration
                );

                _setZoomSize( containerWidth, containerHeight );
                _zoomTick( startTime, duration, fromRect, toRect, changeAmounts, isInZoomMode, isSmartPanelingOn );
            } else {
                if( isInZoomMode ) {
                    setAtPanelNum( 'zoom' );
                    _setZoomSize( toRect.containerWidth, toRect.containerHeight );
                } else {
                    if( isSmartPanelingOn ){
                        setAtPanelNum( curPanelNum );
                    } else {
                        setAtPanelNum( 'page' );
                    }
                    _setZoomSize( null, null );
                }
                printReaderDispatch({
                    type: printReaderActions.END_BOOK_ANIMATION
                });
            }
        });

    }, [
        curPanelNum,
        printReaderDispatch
    ]);

    const _panToPanel = useCallback(( fromRect, toRect, duration, startTime, isPageExit = false ) => {
        if( !isAnimating && toRect ) {
            const changeAmounts = {
                x: toRect.x - fromRect.x,
                y: toRect.y - fromRect.y,
                width: toRect.width - fromRect.width,
                height: toRect.height - fromRect.height
            };

            printReaderDispatch({
                type: printReaderActions.START_BOOK_ANIMATION
            });

            _panTick( startTime, duration, fromRect, toRect, changeAmounts, isPageExit );
        }
    }, [
        _panTick,
        isAnimating,
        printReaderDispatch
    ]);

    const _crossfadeToPanel = useCallback(( fromRect, toRect, duration, startTime ) => {
        printReaderDispatch({
            type: printReaderActions.START_BOOK_ANIMATION
        });
        _setCrossFadingAlpha( 0, fromRect, toRect );
        _crossfadeTick( startTime, duration );
    }, [
        printReaderDispatch,
        _crossfadeTick
    ]);

    const goToPanel = useCallback(( fromPanelNum, toPanelNum ) => {
        if( fromPanelNum !== toPanelNum ) {
            const fromPanelInfo = panels[ fromPanelNum ];
            const toPanelInfo = panels[ toPanelNum ];
            const direction = ( fromPanelNum < toPanelNum ) ? 'forwards' : 'backwards';
            const transition = ( direction === 'forwards' ) ?
                toPanelInfo.transitions_forward[ 0 ] :
                fromPanelInfo.transitions_forward[ 0 ];
            const startTime = Date.now();
            const duration = transition.duration * 1000;

            const fromRect = {
                x: fromPanelInfo.x,
                y: fromPanelInfo.y,
                width: fromPanelInfo.width,
                height: fromPanelInfo.height,
                maskColor: fromPanelInfo.mask_color
            };

            const toRect = {
                x: toPanelInfo.x,
                y: toPanelInfo.y,
                width: toPanelInfo.width,
                height: toPanelInfo.height,
                maskColor: toPanelInfo.mask_color
            };

            if( transition.type === 'pan' ) {
                _panToPanel( fromRect, toRect, duration, startTime );
            } else if( transition.type === 'crossfade' ) {
                _crossfadeToPanel( fromRect, toRect, duration, startTime );
            }
        }
    }, [
        _crossfadeToPanel,
        _panToPanel,
        panels
    ]);

    const changePanelingMode = useCallback(( panelingState, curPanelNum ) => {
        const fromPanelInfo = panels[ curPanelNum ];
        const startTime = Date.now();
        const duration = 500;
        const panelRect = fromPanelInfo ? {
            x: fromPanelInfo.x,
            y: fromPanelInfo.y,
            width: fromPanelInfo.width,
            height: fromPanelInfo.height,
            maskColor: fromPanelInfo.mask_color
        } : null;

        if(panelRect &&
            (pageRect.x !== panelRect.x ||
            pageRect.y !== panelRect.y ||
            pageRect.width !== panelRect.width ||
            pageRect.height !== panelRect.height)
        ) {
            if( panelingState === 'off' ) {
                _panToPanel( panelRect, pageRect, duration, startTime, true );
            } else if( panelingState === 'on' ) {
                _panToPanel( pageRect, panelRect, duration, startTime );
            }
        } else {
            if( panelingState === 'off' ) {
                setAtPanelNum( 'page' );
            } else {
                setAtPanelNum( curPanelNum );
            }
        }

    }, [
        _panToPanel,
        panels,
        pageRect
    ]);

    const changeZoomMode = useCallback(( fromRect, toRect, isInZoomMode, isSmartPanelingOn ) => {
        if( fromRect && toRect ) {
            const startTime = Date.now();
            const duration = 250;
            setAtPanelNum( 'zoom' );

            const changeAmounts = {
                containerWidth: toRect.containerWidth - fromRect.containerWidth,
                containerHeight: toRect.containerHeight - fromRect.containerHeight
            };
            printReaderDispatch({
                type: printReaderActions.START_BOOK_ANIMATION
            });
            if( isSmartPanelingOn ) {
                const panChangeAmounts = {
                    x: toRect.x - fromRect.x,
                    y: toRect.y - fromRect.y,
                    width: toRect.width - fromRect.width,
                    height: toRect.height - fromRect.height,
                    containerWidth: toRect.containerWidth - fromRect.width,
                    containerHeight: toRect.containerHeight - fromRect.height
                };

                _panTick( startTime, duration, fromRect, toRect, panChangeAmounts, false, true );
            } else {
                _zoomTick( startTime, duration, fromRect, toRect, changeAmounts, isInZoomMode, isSmartPanelingOn );
            }
        }
    }, [
        _panTick,
        _zoomTick,
        printReaderDispatch
    ]);

    return {
        x,
        y,
        width,
        height,
        fromX,
        fromY,
        fromWidth,
        fromHeight,
        alpha,
        atPanelNum,
        isCrossfading,
        maskColor,
        fromMaskColor,
        containerWidth,
        containerHeight,
        goToPanel,
        changePanelingMode,
        changeZoomMode
    };
};

export default usePanelTransitionStatus;