export default class ComponentService {
    // Needs to be overridden to avoid conflicts on extension
    static instance = null;

    _listeners = {};
    _scripts = {};

    /**
     * Gets a single instance of the service across components
     * @return {ComponentService} The instance of the service.
     */
    static getService(...extraParams) {

        if( this.instance === null ) {
            this.instance = new this(...extraParams);
        }

        return this.instance;
    }

    destroy() {
        this.resetListeners();
    }

    /**
     * Adds permanent listener to service.
     * @param    {String}     evt            Name of event subscribing to.
     * @param    {Function} callback Function to be called when that event triggers.
     */
    on( evt, callback ) {
        if( !this._listeners[ evt ] ) {
            this._listeners[ evt ] = {};
        }
        if( !this._listeners[ evt ].on ) {
            this._listeners[ evt ].on = new Set();
        }
        this._listeners[ evt ].on.add( callback );
    }

    /**
     * Adds listener to service that is only called once.
     * @param    {String}     evt            Name of event subscribing to.
     * @param    {Function} callback Function to be called when that event triggers.
     */
    one( evt, callback ) {
        if( !this._listeners[ evt ] ) {
            this._listeners[ evt ] = {};
        }
        if( !this._listeners[ evt ].one ) {
            this._listeners[ evt ].one = new Set();
        }
        this._listeners[ evt ].one.add( callback );
    }

    /**
     * Removes listener from service.
     * @param    {String}     evt            Name of event subscribed to.
     * @param    {Function} callback Function assosciated with event.
     */
    off( evt, callback ) {
        if( this._listeners[ evt ] ) {
            if( this._listeners[ evt ].on ) {
                this._listeners[ evt ].on.delete( callback );
            }
            if( this._listeners[ evt ].one ) {
                this._listeners[ evt ].one.delete( callback );
            }
        }
    }

    /**
     * Fires all callbacks associated with and event in the service.
     * @param    {String} evt    Name of event to be fired.
     * @param    {Object} data Data to be communitcated with callback
     * @param    {Boolean} triggerServerSide Bypasses server-side check, if necessary
     */
    trigger( evt, data = {}, triggerServerSide = false ) {
        if( !this.isServer() || triggerServerSide ) {
            if( this._listeners[ evt ] ) {
                if( this._listeners[ evt ].on ) {
                    this._listeners[ evt ].on.forEach( ( callback ) => {
                        callback( { ...data, eventType: evt } );
                    });
                }
                if( this._listeners[ evt ].one ) {
                    this._listeners[ evt ].one.forEach( ( callback ) => {
                        callback( { ...data, eventType: evt } );
                    });
                    this._listeners[ evt ].one.clear();
                }
            }

            if( evt.split( ':' )[ 1 ] ) {
                this.trigger( evt.split( ':' )[ 0 ], data );
            }
        }
    }

    /**
     * Loads JS for third-party player API. Ensures that
     * any script will only get loaded once.
     * @param    {String} url URL for the script to be loaded.
     * @return {Promise}         Promise resolved once the script is loaded.
     */
    loadScript( url ) {
        if( !this._scripts[ url ] ) {
            this._scripts[ url ] = new Promise( ( resolve, reject ) => {
                const script = document.createElement( 'script' );
                script.url = url;
                script.type = 'text/javascript';
                script.src = url;
                script.addEventListener( 'load', resolve );
                document.getElementsByTagName( 'head' )[ 0 ].appendChild( script );
            });
        }

        return this._scripts[ url ];
    }

    loadCSS( url ) {
        let asset = document.createElement( 'link' );
        asset.rel = 'stylesheet';
        asset.type = 'text/css';
        asset.href = url;
    }

    /**
     * Wipes out listeners.
     */
    resetListeners() {
        this._listeners = {};
    }

    isServer() {
        return (typeof window === 'undefined');
    }

    /**
     * Wipes out instance (mostly for unit tests. might not be desireable in web app.).
     */
    static resetInstance() {
        this.instance = null;
    }
}
