import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { PrintReaderContext } from './printReaderContext';
import usePanelTransitionStatus from "./usePanelTransitionStatus";

export const PrintPageContext = createContext();

export const PrintPageProvider = ( props ) => {
    const { printReaderState, printReaderDispatch } = useContext( PrintReaderContext );
    const curPageInfo = printReaderState.curBook.pages[ props.pageNum ];
    const panels = curPageInfo.panels;

    const {
        pageRect
    } = props;

    // If panel does not exist, treat it like a full page panel
    const startRectSource = ( props.atPanelNum === 'page' || props.atPanelNum === 'zoom' ) ?
        pageRect :
        panels[ props.atPanelNum ] ?? pageRect;
    const startingStatus = {
        x: startRectSource.x,
        y: startRectSource.y,
        width: startRectSource.width,
        height: startRectSource.height,
        maskColor: startRectSource.mask_color,
        atPanelNum: props.atPanelNum,
        containerWidth: ( printReaderState.isInZoomMode ) ? pageRect.width : null,
        containerHeight: ( printReaderState.isInZoomMode ) ? pageRect.height : null
    };

    const panelTransitionStatus = usePanelTransitionStatus({
        ...startingStatus,
        panels,
        printReaderDispatch,
        printReaderState,
        pageRect
    });

    return (
        <PrintPageContext.Provider
            value = {{
                panelTransitionStatus,
                curPageInfo
            }}
        >
            { props.children }
        </PrintPageContext.Provider>
    );
};

PrintPageProvider.propTypes = {
    pageRect: PropTypes.object,
    children: PropTypes.element.isRequired,
    pageNum: PropTypes.number,
    atPanelNum: PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] )
};