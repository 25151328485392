export const easing = {
    /**
   * Formula for quadratic ease out
   * @param  {Number} elapsedTime Time that has elapsed in the animation thus far
   * @param  {Number} startValue  Starting value for the animation
   * @param  {Number} valueChange The amount the value needs to change within the animation
   * @param  {Number} duration    Total time the animation take to complete
   * @return {Number}             The current state of the animation.
   */
    easeOutQuad(elapsedTime, startValue, valueChange, duration) {
        elapsedTime /= duration;
        return -valueChange * elapsedTime * ( elapsedTime - 2 ) + startValue;
    },

    /**
   * Formula for quadratic ease in and out
   * @param  {Number} t Time that has elapsed in the animation thus far
   * @param  {Number} b Starting value for the animation
   * @param  {Number} c The amount the value needs to change within the animation
   * @param  {Number} d Total time the animation take to complete
   * @return {Number}   The current state of the animation.
   */
    easeInOutQuad(t, b, c, d) {
        t /= d / 2;
        if (t < 1) {
            return c / 2 * t * t + b;
        }
        t = t - 1;
        return -c / 2 * ( t * ( t - 2 ) - 1 ) + b;
    },

    /**
   * Formula for circular ease out
   * @param  {Number} t Time that has elapsed in the animation thus far
   * @param  {Number} b Starting value for the animation
   * @param  {Number} c The amount the value needs to change within the animation
   * @param  {Number} d Total time the animation take to complete
   * @return {Number}   The current state of the animation.
   */
    easeOutCirc(t, b, c, d) {
        t /= d;
        t = t - 1;
        return c * Math.sqrt(1 - t * t) + b;
    },

    /**
   * Formula for circular ease in and out
   * @param  {Number} t Time that has elapsed in the animation thus far
   * @param  {Number} b Starting value for the animation
   * @param  {Number} c The amount the value needs to change within the animation
   * @param  {Number} d Total time the animation take to complete
   * @return {Number}   The current state of the animation.
   */
    easeInOutCirc(t, b, c, d) {
        t /= d / 2;
        if (t < 1) {
            return -c / 2 * ( Math.sqrt(1 - t * t) - 1 ) + b;
        }
        t -= 2;
        return c / 2 * ( Math.sqrt(1 - t * t) + 1 ) + b;
    }
};

export const getTransitionEndEventName = () => {
    const el = document.createElement( 'div' );

    const transEndEventNames = {
        WebkitTransition: 'webkitTransitionEnd',
        MozTransition: 'transitionend',
        OTransition: 'oTransitionEnd otransitionend',
        transition: 'transitionend'
    };

    for( let key in transEndEventNames) {
        if( el.style[ key ] !== undefined ) {
            return transEndEventNames[ key ];
        }
    }

    return false;
};

/**
 * Logs the complete opbject. Default depth is 2 levels.
 * @param {object} obj to be displayed
 * @param {string} str optional separator title
 */
export const logObj = (obj, str) => {
    console.log(
        `%c/// ${str ? str : 'logObj'}`,
        'color: purple; font-weight: bold'
    );
    console.dir(obj, { depth: null, colors: true });
};

/**
 * Determines if the given comic is part of a free promotion - MARVEL-4410 and MARVEL-6782
 * @param {Number} id comic ID
 */
export const getIsFreePromo = (id) => {
    return [57497, 61712, 57436, 58466, 62847, 59310, 61815, 57396, 62599, 62622, 59249, 61805, 57416].includes(id);
};