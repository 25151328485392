import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from './../hooks/appContext';
import ProgressBar from "./ProgressBar";
import get from 'lodash/get';

const Header = (props) => {
    const { readerContext, readerState, headerClass } = props;
    const {
        toggleAllPagesOpen,
        toggleTooltipsOpen,
        toggleBookInfoOpen
    } = useContext( readerContext );
    const {
        idleTimeoutStatus
    } = useContext( AppContext );
    const {
        isIdle
    } = idleTimeoutStatus;

    const {
        isAllPagesOpen,
        numPagesAvailable,
        curPageNum,
        numPageImagesPreloaded,
        curBook,
        isHeaderLocked
    } = readerState;

    const {
        partner_logo
    } = curBook;

    const hideShowInfoButton = !!(
        isAllPagesOpen
    );
    const title = get( curBook, 'issue_meta.title' );

    const hideHelpButton = !!(
        isAllPagesOpen
    );

    useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <section id="header">
            <header className={ `
                    ${ ( !isHeaderLocked && isIdle ) ? 'slideAway' : '' }
                    ${ headerClass ? headerClass : '' }
            `}>
                <span
                    className={ `icon allPages${( isAllPagesOpen )?' active':''}` }
                    onClick={ toggleAllPagesOpen }
                    role="button"
                    tabIndex="0"
                >
                    {( isAllPagesOpen ) ? 'Back' : ''}
                </span>
                <span
                    className={ `icon showInfo${(hideShowInfoButton)?' hide':''}` }
                    onClick={ toggleBookInfoOpen }
                    role="button"
                    tabIndex="0"
                >
                </span>
                <span
                    className={ `icon help${(hideHelpButton)?' hide':''}` }
                    onClick={ toggleTooltipsOpen }
                    role="button"
                    tabIndex="0"
                >
                </span>
                <h3>
                    <span className="title">
                        { title }
                    </span>
                    <span className="mode-tag">

                    </span>
                </h3>
                <ProgressBar
                    numLoaded = { numPageImagesPreloaded }
                    totalToLoad = { numPagesAvailable }
                    curProgressNum = { curPageNum }
                    totalProgressNum = { numPagesAvailable }
                />
                {
                    partner_logo.url &&
                    <div
                        className={ `partner-logo show-logo ${ ( !partner_logo.link_url ) ? 'no-link' : '' } ` }
                    >
                        SPONSORED BY
                        <br/>
                        <div className="logo-wrapper">
                            {
                                partner_logo.link_url ?
                                    <a
                                        href={ partner_logo.link_url }
                                    >
                                        <img
                                            src={ partner_logo.url }
                                            alt=""
                                        />
                                    </a> :
                                    <img
                                        src={ partner_logo.url }
                                        alt=""
                                    />
                            }
                        </div>
                    </div>
                }
            </header>
        </section>
    );
};

Header.propTypes = {
    readerContext: PropTypes.object,
    readerState: PropTypes.object,
    headerClass: PropTypes.string
};

export default Header;