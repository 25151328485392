/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from 'react';
import { easing } from "../util/helpers";
import { printReaderActions } from "./printReaderReducer";

const usePageTransitionStatus = ( props ) => {
    const [ toAlpha, setToAlpha ] = useState( 0 );
    const [ toSlidePos, setToSlidePos ] = useState( null );
    const [ fromSlidePos, setFromSlidePos ] = useState( null );
    const [ atPageNum, setAtPageNum ] = useState( props.atPageNum );
    const { printReaderDispatch} = props;

    const _crossfadeToPage = useCallback(( toPageNum, toPanel ) => {
        const _crossfadeTick = ( startTime, duration, toPageNum ) => {
            window.requestAnimationFrame(() => {
                const updateTime = Date.now();
                const elapsedTime = updateTime - startTime;
                if( duration && duration >= elapsedTime ) {
                    const alpha = easing.easeInOutQuad(
                        elapsedTime,
                        0,
                        1,
                        duration
                    );
                    setToAlpha( alpha );
                    _crossfadeTick( startTime, duration, toPageNum );
                } else {
                    setAtPageNum( toPageNum );
                    setToAlpha( null );
                    printReaderDispatch({
                        type: printReaderActions.END_BOOK_ANIMATION
                    });
                }
            });
        };
        const transition = toPanel?.transitions_forward[ 0 ];
        const startTime = Date.now();
        const duration = transition?.duration * 1000;
        printReaderDispatch({
            type: printReaderActions.START_BOOK_ANIMATION
        });
        _crossfadeTick( startTime, duration, toPageNum );
    }, [ printReaderDispatch ]);

    const _slideToPage = useCallback(( toPageNum, direction ) => {
        const _slidePageTick = ( startTime, duration, toPageNum, positions ) => {
            window.requestAnimationFrame(() => {
                const updateTime = Date.now();
                const elapsedTime = updateTime - startTime;
                if( duration >= elapsedTime ) {
                    const toSlidePos = easing.easeInOutQuad(
                        elapsedTime,
                        positions.toStart,
                        positions.change,
                        duration
                    );
                    const fromSlidePos = easing.easeInOutQuad(
                        elapsedTime,
                        positions.fromStart,
                        positions.change,
                        duration
                    );

                    setToSlidePos( toSlidePos );
                    setFromSlidePos( fromSlidePos );
                    setToAlpha( 1 );
                    _slidePageTick( startTime, duration, toPageNum, positions );
                } else {
                    setAtPageNum( toPageNum );
                    setToSlidePos( null );
                    setFromSlidePos( null );
                    setToAlpha( null );
                    printReaderDispatch({
                        type: printReaderActions.END_BOOK_ANIMATION
                    });
                }
            });
        };
        const startTime = Date.now();
        const duration = 500;

        let positions;

        if( direction === 'forwards' ) {
            positions = {
                toStart: 100,
                fromStart: 0,
                change: -100
            };
        } else if( direction === 'backwards' ) {
            positions = {
                toStart: -100,
                fromStart: 0,
                change: 100
            };
        }

        printReaderDispatch({
            type: printReaderActions.START_BOOK_ANIMATION
        });

        _slidePageTick( startTime, duration, toPageNum, positions );
    }, [
        printReaderDispatch
    ]);

    const goToPage = useCallback(( toPageNum, isSmartPanelingOn, toPanel, direction, isInZoomMode ) => {
        printReaderDispatch({ type: printReaderActions.START_BOOK_ANIMATION });
        if( direction === 'direct' ) {
            _crossfadeToPage( toPageNum, toPanel );
        } else if( isSmartPanelingOn || isInZoomMode) {
                _crossfadeToPage( toPageNum, toPanel );
        } else {
            _slideToPage( toPageNum, direction );
        }
        
    }, [
        printReaderDispatch,
        _crossfadeToPage,
        _slideToPage
    ]);

    return {
        goToPage,
        toAlpha,
        toSlidePos,
        fromSlidePos,
        atPageNum
    };
};

export default usePageTransitionStatus;