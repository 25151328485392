import { useState, useLayoutEffect } from 'react';

const useViewportSize = () => {
    const [ viewportWidth, setWidth ] = useState( window.innerWidth );
    const [ viewportHeight, setHeight ] = useState( window.innerHeight );

    useLayoutEffect(() => {
        const _updateSize = () => {
            setWidth( window.innerWidth );
            setHeight( window.innerHeight );
        };
        window.addEventListener( 'resize', _updateSize );
    }, []);
    return {
        viewportWidth,
        viewportHeight
    };
};

export default useViewportSize;