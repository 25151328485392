import { useEffect } from "react";
import PropTypes from "prop-types";
import { uatuActions, manageDataLayer } from "./uatu";
import get from "lodash/get";
import camelCase from "lodash/camelCase";
import extend from "lodash/extend";

const useUatuForVertReader = (props) => {
  const { verticalReaderState } = props;

  const {
    displayPageNum,
    curPageInfo,
    curBook,
    isAtEndScreen,
    isBookInfoOpen,
    isTooltipsOpen,
  } = verticalReaderState;
  const { issue_meta, auth_state, id, is_free, numberOfPages } = curBook;

  const {
    title,
    release_date,
    rating,
    series_id,
    series_title,
    imprint,
    purchase_data,
    format
  } = issue_meta;

  const { logged_in, subscriber } = auth_state;

  useEffect(() => {
    if (id) {
      let partnerTitleWithId;
      // TODO: Grab this data point during or after white lable work
      // if(this.controlsModel.get( 'route' ) === 'labelbook') {
      //     partnerTitleWithId = ( partner_logo.id || '' ) +
      //     ' | ' +
      //     ( partner_logo.description || 'partnerbook' );
      // } else {
      partnerTitleWithId = "generic";
      // }

      const creators = curBook?.issue_meta?.creators?.extended_list?.map(element => {
        return {[camelCase(element.role)]: element.full_name} || [];
      });

      manageDataLayer({
        type: uatuActions.REPORT_BOOK_LOAD,
        payload: extend({
          digital_comic_id: id,
          digital_comic_title_with_id: id + " | " + title,
          coverVariant: "",
          availableDigital: "1",
          isFree: is_free,
          publishDate: release_date,
          rating: rating,
          price: get(purchase_data, ".digital_price"),
          seriesId: series_id,
          seriesName: series_title,
          previewMode: !logged_in || !subscriber,
          totalPages: numberOfPages,
          tags: "",
          imprint: imprint || "Unknown",
          partnerTitleWithId: partnerTitleWithId,
          isPreview: !logged_in || !subscriber,
          appName: "Marvel Unlimited",
          fullscreen: false,
          content_format: format === "Comic" ? "standard" : "vertical"
        }, 
        ...creators)
      });
    }
  }, [
    title,
    release_date,
    rating,
    series_id,
    series_title,
    imprint,
    id,
    is_free,
    numberOfPages,
    logged_in,
    subscriber,
    purchase_data,
    curBook,
    format
  ]);

  useEffect(() => {
    if (curPageInfo) {
      manageDataLayer({
        type: uatuActions.REPORT_PAGE_OR_PANEL_CHANGE,
        payload: {
          pageNumber: parseInt(displayPageNum) + 1,
          totalPages: numberOfPages,
          pageId: curPageInfo.id,
        },
      });
    }
  }, [numberOfPages, displayPageNum, curPageInfo]);

  // useEffect(() => {
  //     let readingMode = ( isSmartPanelingOn ) ? 'smart panel' : 'page';
  //     readingMode = ( isInZoomMode ) ? 'page zoom' : readingMode;

  //     manageDataLayer({
  //         type: uatuActions.REPORT_READING_MODE,
  //         payload: {
  //             readingMode
  //         }
  //     });
  // }, [
  //     isSmartPanelingOn,
  //     isInZoomMode
  // ]);

  useEffect(() => {
    let modalName;
    if (isBookInfoOpen) {
      modalName = "info";
    } else if (isTooltipsOpen) {
      modalName = "tip";
    } 
    // else if (isAtEndScreen) {
    //   const isPreview = !!(!logged_in || !subscriber);
    //   modalName = isPreview ? "eob:nonsub" : "eob:sub";
    // }
    if (modalName) {
      manageDataLayer({
        type: uatuActions.REPORT_SHOW_MODAL,
        payload: {
          modalName,
        },
      });
    }
  }, [isTooltipsOpen, isBookInfoOpen, logged_in, subscriber, isAtEndScreen]);

  //   useEffect(() => {
  //     console.log("//// REPORT_END_SCREEN_CLOSE");
  //     if (!isAtEndScreen) {
  //       manageDataLayer({
  //         type: uatuActions.REPORT_END_SCREEN_CLOSE,
  //         payload: {
  //           isAtEndScreen,
  //         },
  //       });
  //     }
  //   }, [isAtEndScreen]);

  // useEffect(() => {
  //     manageDataLayer({
  //         type: uatuActions,
  //         payload: {
  //         }
  //     });
  // }, []);

  return null;
};

useUatuForVertReader.propTypes = {
  uatu: PropTypes.func,
  verticalReaderState: PropTypes.func,
};

export default useUatuForVertReader;
