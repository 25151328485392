import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SpotlightItem from '../Spotlight/SpotilightItem';
import './spotlight-section.scss';

const Spotlight = ( props ) => {
    const {
        discoverData,
        containerClassName
    } = props;

    const spotlightItems = useMemo(()=>{
        const items = [];
        for( let i = 0; i < discoverData.length; i++ ) {
            const item = discoverData[ i ];
            items.push(
                <SpotlightItem
                    key = { `item-${ i }` }
                    title = { item.spotlight.title }
                    tag = { item.spotlight.tag }
                    bookIndex = { item.bookIndex }
                    images = { item.spotlight.images }
                    slides = { item.contents }
                />
            );
        }
        return items;
    }, [
        discoverData
    ]);

    return (
        <section
            id="spotlight"
            className= { `${containerClassName}` }
        >
            <h3>DISCOVER</h3>
            { spotlightItems }
        </section>
    );
};

Spotlight.propTypes = {
    discoverData: PropTypes.array,
    containerClassName: PropTypes.string
};

export default Spotlight;