import isObject from "lodash/isObject";
import isBoolean from "lodash/isBoolean";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import extend from "lodash/extend";

export const uatuActions = {
  REPORT_BOOK_LOAD: "reportBookLoad",
  REPORT_PAGE_OR_PANEL_CHANGE: "reportPageOrPanelChange",
  // REPORT_PANEL_VIEW: 'reportPanelView',
  // REPORT_PERCENT_READ: 'reportPercentRead',
  // REPORT_TIME_SPENT: 'reportTimeSpent',
  REPORT_READING_MODE: "reportReadingMode",
  // REPORT_FULL_SCREEN: 'reportFullScreen',
  REPORT_READ_MORE: 'reportReadMore',
  // REPORT_LINK_CLICKED: 'reportLinkClicked',
  REPORT_TOC_CLICK: "reportTocClick",
  REPORT_SHOW_MODAL: "reportShowModal",
  REPORT_END_SCREEN_CLOSE: "reporEndScreenClose",
  // UPDATE_ISSUE_DATA: 'updateIssueData',
  // UPDATE_READING_PROGRESS: 'updateReadingProgress'
};

const initialDataLayerState = {
  pageInfo: {
    pageName: "web reader",
    channel: "comics",
    pageId: "",
  },
  category: {
    businessunit: "marvel unlimited",
    contentType: "comics",
    subSectionL2: "",
    subSectionL3: "",
    subSectionL4: "",
  },
  comicDetail: {
    remainingMilestones: [],
  },
  userInfo: {}
};

const initialMilestones = ["0.25", "0.50", "0.75", "1"];

const _reformatString = (value) => {
  let formattedString;

  if (typeof value !== "string") {
    if (isBoolean(value)) {
      value = value ? "1" : "0";
    } else {
      try {
        value = value.toString();
      } catch (err) {
        value = null;
      }
    }
  }

  formattedString = value ? value.toLowerCase() : "";
  return formattedString;
};

// Variable to track timeSpent
let startReadTime = 0;

const _reformatAll = (data) => {
  let reformatedData = null;
  if (data && isObject(data)) {
    reformatedData = {};
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        reformatedData[key] = _reformatString(data[key]);
      }
    }
  }
  return reformatedData;
};

const _calculateMilestone = (totalNum, milestones, pageNumber) => {
  let lastMilestone = window.digitalData.comicDetail.lastMilestone;
  let currentMilestone =
    window.digitalData.comicDetail.readingMilestone || lastMilestone || 0;
  if (milestones && milestones.length) {
    milestones = milestones.reduce((mstones, percentageEvent) => {
      if (pageNumber / totalNum >= percentageEvent) {
        currentMilestone = _reformatString(percentageEvent * 100);
      } else {
        mstones.push(percentageEvent);
      }
      return mstones;
    }, []);
  }
  let readingMilestone =
    currentMilestone !== lastMilestone ? currentMilestone : "";
  lastMilestone = currentMilestone;

  return {
    readingMilestone,
    lastMilestone,
    remainingMilestones: milestones,
  };
};

export const setInitialDataLayerState = () => {
  window.digitalData = merge((window.digitalData || {}), initialDataLayerState);
};
export const track = (bookAction, bookActionValue, trackData) => {
  let data = cloneDeep(trackData);
  data = _reformatAll(data);

  // TODO: Figure out what to do with alt tracking when customize for kids reader.
  /*if( typeof rocket.custom.postAnalytics === 'function' ) {
        rocket.custom.postAnalytics( bookAction, bookActionValue, data, window.digitalData );
    } else */
  if (window._satellite && window._satellite.track) {
    window._satellite.track(bookAction, data);
  }
};

export const manageDataLayer = (action = { payload: {} }) => {
  let state = cloneDeep(window.digitalData);
  let bookAction;
  let bookActionValue;
  let extraBookActions = [];
  let extraBookActionValues = [];
  let extraBookTrackData = [];

  let { comicDetail } = state;

  let didChange = false;

  let { payload, type, trackData } = action;
  payload = _reformatAll(payload);

  switch (type) {
    case uatuActions.REPORT_BOOK_LOAD:
      if (state.digital_comic_id !== payload.digital_comic_id) {
        comicDetail = extend(
          cloneDeep(initialDataLayerState.comicDetail),
          payload
        );
        comicDetail.remainingMilestones = cloneDeep(initialMilestones);
        if(state?.readingMode) {
          comicDetail.readingMode = state.readingMode;
        }
        bookAction = "start";
        startReadTime = new Date().getTime();
      }
      break;
    case uatuActions.REPORT_PAGE_OR_PANEL_CHANGE:
      if (payload.pageNumber && payload.pageNumber !== null) {
        if (state.comicDetail.pageNumber !== payload.pageNumber) {
          bookActionValue = "page_number:" + payload.pageNumber;

          if (payload.totalPages) {
            payload = extend(
              payload,
              _calculateMilestone(
                payload.totalPages,
                comicDetail.remainingMilestones,
                payload.pageNumber
              )
            );
          }
          didChange = true;
        } else if (state.comicDetail.panel_number !== payload.panelId) {
          bookActionValue = "panel_number:" + payload.panelId;
          didChange = true;
        }
        if (didChange) {
          bookAction = payload.readingMilestone
            ? payload.readingMilestone + "progress"
            : "read";

          const timeSpent = {"timeSpent": (new Date().getTime() - startReadTime)};
          
          comicDetail = extend(comicDetail, payload, timeSpent);
        }
      }
      break;
    case uatuActions.REPORT_READING_MODE:
      if (payload.readingMode !== comicDetail.readingMode) {
        bookAction = payload.doubleTap === '1' ? "smartPaneldTap" : "smartPanelButton";
        bookActionValue = payload.readingMode;
        
        comicDetail = extend(
          comicDetail, 
          payload,
          {smartPanelToggle: (payload.readingMode !== 'page')}
        );
      }
      break;
    case uatuActions.REPORT_SHOW_MODAL:
      bookAction = payload.modalName + "Click";
      // console.log("//// REPORT_SHOW_MODAL", bookAction);
      bookActionValue = payload.modalName;
      comicDetail.lastModal = payload.modalName;
      break;
    case uatuActions.REPORT_END_SCREEN_CLOSE:
      // if (
      //   comicDetail.lastModal === "eob:nonsub" ||
      //   comicDetail.lastModal === "eob:sub"
      // ) {
        // console.log("//// REPORT_END_SCREEN_CLOSE");
        bookAction = "read";
        comicDetail.lastModal = null;
      // }
      break;
    case uatuActions.REPORT_TOC_CLICK:
      // console.log("///// ~ manageDataLayer ~ REPORT_TOC_CLICK:");
      bookAction = "tocClick";
      bookActionValue = "toc_page_number:" + payload.pageNumber;
      break;
    // ADOPS IS NO LONGER TRACKING
    // case uatuActions.REPORT_FULL_SCREEN:
    //     if( payload.fullscreen !== comicDetail.fullscreen ) {
    //         bookAction = 'click';
    //         bookActionValue = 'settings:fullScreen' + payload.fullscreen;
    //         comicDetail = extend(
    //             comicDetail,
    //             payload
    //         );
    //     }
    //     break;
    case uatuActions.REPORT_READ_MORE:
        bookAction = 'nextIssue';
        bookActionValue = 'end:' + payload.from;
        trackData = {
            end_id: payload.id + ' | ' + payload.title
        };
        comicDetail.lastModal = null;
        break;
    // case uatuActions.REPORT_LINK_CLICKED:
    //     bookAction = 'click';
    //     bookActionValue = payload.type + ':link';
    //     trackData = {
    //         uri: payload.url
    //     };
    //     break;
    default:
      break;
  }

  if (bookAction) {
    comicDetail.bookAction = bookAction;
    comicDetail.bookActionValue = bookActionValue;
    state.comicDetail = comicDetail;
    window.digitalData = state;
    // console.log("///// TRACKING", { bookAction, bookActionValue, trackData }); 
    track(bookAction, bookActionValue, trackData);

    if (extraBookActions.length > 0) {
      for (let i = 0; i < extraBookActions.length; i++) {
        const action = (window.digitalData.comicdetail.bookAction =
          extraBookActions[i]);
        const actionValue = (window.digitalData.comicdetail.bookActionValue =
          extraBookActionValues[i] || null);
        const data = extraBookTrackData[i] || null;
        // console.log("///// EXTRA TRACKING", { action, actionValue, data });
        track(action, actionValue, data);
      }
    }
  } else {
    comicDetail.bookAction = null;
    comicDetail.bookActionValue = null;

    window.digitalData = state;
  }
  return state;
};
