import React from 'react';
import PropTypes from 'prop-types';
import SpotlightSlider from './SpotlightSlider';

const SpotlightItem = ( props ) => {
    const {
        slides,
        images,
        tag,
        title,
        bookIndex
    } = props;

    return (
        <div className="spotlight_container">

            <div className="bkg_spotlight_container">
                {
                    images && images[0] &&
                    <div
                        className="img_sl_cont"
                        style={{
                            backgroundImage: `url('${images[0].path}/rotator_xlarge_uncanny.jpg')`
                        }}
                    >

                        <div className="img-skrim"></div>
                        <h6> { tag } </h6>

                        <div className="series_title"> { title } </div>

                        <SpotlightSlider
                            slides = { slides }
                            bookIndex = { bookIndex || 0 }
                        />
                    </div>
                }
            </div>
        </div>
    );
};

SpotlightItem.propTypes = {
    images: PropTypes.array,
    slides: PropTypes.array,
    tag: PropTypes.string,
    title: PropTypes.string,
    bookIndex: PropTypes.number
};

export default SpotlightItem;