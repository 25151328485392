import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import PageContainer from "./PrintPageContainer";
import PageImage from "./PrintPageImage";
import ClipPath from "./ClipPath";
import { PrintReaderContext } from '../../hooks/printReaderContext';
import { PrintPageContext } from "../../hooks/printPageContext";
import get from 'lodash/get';

const PrintPage = ( props ) => {
    const { printReaderState } = useContext( PrintReaderContext );
    const printPageContext = useContext( PrintPageContext );
    // If ghost page, no panel transition
    const panelTransitionStatus = ( printPageContext ) ? printPageContext.panelTransitionStatus : null;

    const {
        isGhostPage,
        imageURL,
        ghostPanelRect,
        pageRect,
        id,
        pageWidth,
        pageHeight
    } = props;

    const {
        changePanelingMode,
        atPanelNum,
        goToPanel,
        changeZoomMode,
        isCrossfading
    } = ( panelTransitionStatus ) ? panelTransitionStatus : {}; // If ghost page, no panel transition

    const {
        isSmartPanelingOn,
        isAnimating,
        changeType,
        curPanelNum,
        prevPanelNum,
        isInZoomMode,
        curPanels
    } = printReaderState;

    const shouldCrossFadePanel = !!(
        !isGhostPage &&
        isCrossfading &&
        isSmartPanelingOn
    );

    let fromImage = imageURL;

    let rectSource;
    if( isGhostPage ) {
        rectSource = ghostPanelRect;
    } else if(!curPanels ||
        (changeType === 'page' &&
        !isSmartPanelingOn)
    ) {
        rectSource = pageRect;
    } else {
        rectSource = panelTransitionStatus;
    }

    let {
        x,
        y,
        width,
        height,
        maskColor,
        fromX,
        fromY,
        fromWidth,
        fromHeight,
        fromMaskColor
    } = rectSource;

    maskColor = ( isSmartPanelingOn && !maskColor ) ?
        get( curPanels, `[${ curPanelNum }].mask_color` ) :
        maskColor;

    const alpha = ( changeType === 'page' ) ?
        props.alpha || 0 :
        panelTransitionStatus.alpha;

    const leftPos = props.leftPos || 0;

    let containerWidth = '100%';
    let containerHeight = '100%';
    if(
        isGhostPage
        && isInZoomMode
    ) {
        containerWidth = ghostPanelRect.containerWidth;
        containerHeight = ghostPanelRect.containerHeight;
    } else if(
        panelTransitionStatus &&
        panelTransitionStatus.containerWidth &&
        panelTransitionStatus.containerHeight
    ) {
        containerWidth = panelTransitionStatus.containerWidth;
        containerHeight = panelTransitionStatus.containerHeight;
    }

    const shouldCheckForPageStateChange = !!(
        !isGhostPage &&
        !isAnimating &&
        changeType !== 'page'
    );

    // PANEL CHANGE EFFECT
    const shouldPanelChange = !!(
        shouldCheckForPageStateChange &&
        isSmartPanelingOn &&
        atPanelNum !== curPanelNum &&
        atPanelNum !== 'zoom' &&
        atPanelNum !== 'page'
    );

    useEffect(() => {
        if( shouldPanelChange ) {
            goToPanel( prevPanelNum, curPanelNum );
        }
    }, [
        shouldPanelChange,
        curPanelNum,
        prevPanelNum,
        goToPanel
    ]);

    // SMART PANELING EFFECT
    const hasSmartPanelingTurnedOff = !!(
        shouldCheckForPageStateChange &&
        !isSmartPanelingOn &&
        !isInZoomMode &&
        atPanelNum !== 'page' &&
        atPanelNum !== 'zoom'
    );

    const hasSmartPanelingTurnedOn = !!(
        shouldCheckForPageStateChange &&
        isSmartPanelingOn &&
        atPanelNum !== curPanelNum &&
        atPanelNum !== 'zoom' &&
        atPanelNum === 'page'
    );

    useEffect(() => {
        if( hasSmartPanelingTurnedOff ) {
            changePanelingMode( 'off', curPanelNum );
        } else if( hasSmartPanelingTurnedOn ) {
            changePanelingMode( 'on', curPanelNum );
        }
    }, [
        hasSmartPanelingTurnedOff,
        hasSmartPanelingTurnedOn,
        changePanelingMode,
        curPanelNum
    ]);

    // ZOOM MODE EFFECT
    useEffect(() => {
        const _getImageRect = () => {
            let imageRect = null;
            if( document && document.getElementById ) {
                const imageEl = document.getElementById( id + '_image' );
                if( imageEl && imageEl.getBoundingClientRect ) {
                    imageRect = imageEl.getBoundingClientRect();
                }
            }
            return imageRect;
        };

        if( shouldCheckForPageStateChange ) {
            if(
                isInZoomMode &&
                atPanelNum !== 'zoom'
            ) {
                const imageRect = _getImageRect();

                const zoomFromRect = {
                    containerWidth: imageRect.width,
                    containerHeight: imageRect.height
                };
                const zoomToRect = {
                    containerWidth: pageRect.width,
                    containerHeight: pageRect.height
                };

                changeZoomMode( zoomFromRect, zoomToRect, isInZoomMode );
            } else if(
                atPanelNum === 'zoom' &&
                !isInZoomMode
            ) {
                const windowRatio = window.innerWidth / window.innerHeight;
                const pageRatio = pageRect.width / pageRect.height;
                const sizeTo = ( pageRatio < windowRatio ) ? 'height' : 'width';

                let containerWidth;
                let containerHeight;

                if( sizeTo === 'height' ) {
                    containerHeight = window.innerHeight;
                    containerWidth = ( window.innerHeight / pageRect.height ) * pageRect.width;
                } else if( sizeTo === 'width' ) {
                    containerWidth = window.innerWidth;
                    containerHeight = ( window.innerWidth / pageRect.width ) * pageRect.height;
                }

                const zoomToRect = {
                    containerWidth,
                    containerHeight
                };

                const zoomFromRect = {
                    containerWidth: pageRect.width,
                    containerHeight: pageRect.height
                };

                if(
                    !isSmartPanelingOn || !curPanels
                ) {
                    changeZoomMode(
                        zoomFromRect,
                        zoomToRect,
                        isInZoomMode,
                        isSmartPanelingOn
                    );
                } else if(
                    isSmartPanelingOn
                ) {
                    const toPanel = curPanels[ curPanelNum ];
                    const paneledZoomFromRect = {
                        x: pageRect.x,
                        y: pageRect.y,
                        width: pageRect.width,
                        height: pageRect.height,
                        maskColor: pageRect.maskColor,
                        containerWidth: zoomFromRect.containerWidth,
                        containerHeight: zoomFromRect.containerHeight
                    };
                    const paneledZoomToRect = {
                        x: toPanel.x,
                        y: toPanel.y,
                        width: toPanel.width,
                        height: toPanel.height,
                        maskColor: toPanel.maskColor,
                        containerWidth: zoomToRect.containerWidth,
                        containerHeight: zoomToRect.containerHeight
                    };

                    changeZoomMode(
                        paneledZoomFromRect,
                        paneledZoomToRect,
                        isInZoomMode,
                        isSmartPanelingOn
                    );
                }
            }
        }
    }, [
        shouldCheckForPageStateChange,
        isInZoomMode,
        changeZoomMode,
        isSmartPanelingOn,
        isGhostPage,
        changeType,
        curPanelNum,
        curPanels,
        id,
        isAnimating,
        pageRect,
        atPanelNum
    ]);

    return [
        (
            shouldCrossFadePanel &&
            <PageContainer
                key = 'ghostPanel'
                id = 'ghostPanel'
                alpha = { 1 }
                width = { fromWidth }
                height = { fromHeight }
                containerWidth = { '100%' }
                containerHeight = { '100%' }
                x = { fromX }
                y = { fromY }
                maskColor = { fromMaskColor }
                leftPos = { 0 }
            >
                <PageImage
                    id= { id + '_image_ghost-panel' }
                    width={ pageWidth }
                    height={ pageHeight }
                    clipPathSelector={ '#' + id + '_clip-path_ghost-panel' }
                    imageURL= { fromImage }
                />

                <ClipPath
                    id = { id + '_clip-path_ghost-panel' }
                    rectId = { id + '_rect_ghost-panel' }
                    width = { fromWidth }
                    height = { fromHeight }
                    x = { fromX }
                    y = { fromY }
                />
            </PageContainer>
        ),
        <PageContainer
            key= { id }
            id = { id }
            alpha = { alpha }
            width = { width }
            height = { height }

            containerWidth = { containerWidth }
            containerHeight = { containerHeight }
            x = { x }
            y = { y }
            maskColor = { maskColor }
            leftPos = { leftPos }
        >
            <PageImage
                id = { id + '_image' }
                width = { pageWidth }
                height = { pageHeight }
                clipPathSelector = { '#' + id + '_clip-path' }
                imageURL = { imageURL }
            />

            <ClipPath
                id = { id + '_clip-path' }
                rectId = { id + '_rect' }
                width = { width }
                height = { height }
                x = { x }
                y = { y }
            />
        </PageContainer>
    ];
};
PrintPage.defaultProps = {
    isGhostPage: false,
    alpha: 0
};

PrintPage.propTypes = {
    id: PropTypes.string,
    isGhostPage: PropTypes.bool,
    imageURL: PropTypes.string,
    pageWidth: PropTypes.number,
    pageHeight: PropTypes.number,
    alpha: PropTypes.number,
    pageRect: PropTypes.object
};

export default PrintPage;