import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppProvider } from './hooks/appContext';
import { HashRouter } from "react-router-dom";

const container = document.getElementById( 'root' );
const root = createRoot( container ); //https://dmitripavlutin.com/react-usetransition/

root.render(
    <React.StrictMode>
        <HashRouter hashType = "slash">
            <AppProvider>
                <App />
            </AppProvider>
        </HashRouter>
    </React.StrictMode>,
    container
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
