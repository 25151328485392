import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import NextBook from './NextBook';
import NoBook from './NoBook';
import BuyPage from './BuyPage';
import useLastViewStatus from '../hooks/useLastViewStatus';
import SpotlightSection from './Spotlight/SpotlightSection';

const LastView = ( props ) => {
    const curtainRef = useRef();
    const lastViewContainerRef = useRef();
    const lastViewStatus = useLastViewStatus({
        curtainRef,
        lastViewContainerRef,
        readerContext: props.readerContext
    });

    const {
        isMUUser,
        discoverData,
        nextIssueMeta,
        isFree,
        isFreePromo,
    } = props;

    const {
        curtainClassNameMods,
        lastViewContainerClassNameMods,
        closeLastView
    } = lastViewStatus;

    let lastViewContainerClassName = 'last_page' + lastViewContainerClassNameMods;
    let curtainClassName = 'cd-section background' + curtainClassNameMods;
    let content;

    if( isFree || isMUUser ) {
        if( nextIssueMeta && !isFreePromo ) {
            lastViewContainerClassName += '';
            content = (
                <NextBook
                    thumbnail = { nextIssueMeta.thumbnail }
                    creators = { nextIssueMeta.creators }
                    title = { nextIssueMeta.title }
                    id = { nextIssueMeta.id }
                    releaseDateFormatted = { nextIssueMeta.release_date_digital_formatted }
                    releaseDateDigitalFormatted = { nextIssueMeta.release_date_formatted }
                    rating = { nextIssueMeta.rating }
                    imprint = { nextIssueMeta.imprint }
                    format = { nextIssueMeta.format }
                    features = { nextIssueMeta.features }
                    muReleaseDateFormatted = { nextIssueMeta.mu_release_date_formatted }
                    isFree={ isFree }
                    isMUUser={ isMUUser }
                />
            );

        } else if ( (isFree || isFreePromo)  && !isMUUser ) {
            lastViewContainerClassName += ' freePromoView';
            content = (
                <NoBook
                    isFreePromo={ true }
                />
            );
        } else {
            lastViewContainerClassName += ' defaultView';
            content = (
                <NoBook
                    isFree={ isFree }
                    isMUUser={ isMUUser }
                />
            );
        }
    } else {
        lastViewContainerClassName += ' preview-buypage';
        content = (
            <BuyPage
                isLoggedIn = { props.isLoggedIn }
            />
        );
    }

    return (
        <div className="last_page-wrapper">
            <section key = "curtain" className = { curtainClassName } ref = { curtainRef }>
                <div className="cd-block">
                    <div className="cd-half-block left"></div>
                    <div className="cd-half-block right"></div>
                </div>
            </section>

            <section key = "last_view" className = { 'next-issue-container ' + lastViewContainerClassName } ref = { lastViewContainerRef }>
                <span className='back' onClick = { closeLastView } role="button" tabIndex="0">
                    Back
                </span>
                { content }
            </section>

            {isMUUser && discoverData?.length &&
                <SpotlightSection
                    key = "spotlight"
                    containerClassName = { 'last_page ' + lastViewContainerClassNameMods }
                    discoverData = { discoverData }
                />
            }
        </div>
    );
};

LastView.propTypes = {
    nextIssueMeta: PropTypes.object,
    isMUUser: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    discoverData: PropTypes.array,
    readerContext: PropTypes.object,
    isFree: PropTypes.bool,
    isFreePromo: PropTypes.bool
};

export default LastView;