import React from 'react';
import PropTypes from "prop-types";
import jeffImage from '../images/endofcomics_720.png';


const NoBook = ( props ) => {
    const {
        isFree,
        isFreePromo,
        isMUUser
      } = props;
    if ( (isFree || isFreePromo) && !isMUUser) {
        return [
            <div className="wrapper" key="dontStop">
                <img className="dontStopNow-img" src={ jeffImage } alt='Marvel Characters Together'/>
                <div className="mask" />
                <div className="dontStopNow-wrapper">
                <div className="dontStopNow">
                    <div className="dontStopNow-text"> Want to keep reading? </div>
                    <p>
                        Read over 30,000 Digital Comics including Infinity Comics exclusively on Marvel Unlimited.
                    </p>
                    <p>
                        A World Of Comics Awaits
                    </p>
                    <div className="discoverBtn-Cont">
                        <a
                            className="btn btn-red"
                            href={`${ process.env.REACT_APP_MAIN_SITE }/unlimited?cid=dcom_navigation_20210331_unlimited_top`}
                        >
                            <span> SUBSCRIBE TODAY </span>
                        </a>
                    </div>
                </div>
                </div>
            </div>,
            <div className="dontStopNowLegal" key="dontStopNowLegal">Auto-renewal and other terms apply. See <a href="https://www.marvel.com/unlimited">marvel.com/unlimited</a> for additional details.</div>
        ];
    }
    return (
        <div className="wrapper">
            <div className="mask" />
            <div className="dontStopNow">
                <h1> Don&apos;t stop now </h1>
                <p>
                    Let the editors guide you through a new corner of the&nbsp;
                    Marvel Universe. You&apos;ll find recommended reading for&nbsp;
                    universe-shattering events,
                    plus guides to your favorite characters and artists.
                </p>
                <div className="discoverBtn-Cont">
                    <a
                        className="btn btn-red"
                        href={`${ process.env.REACT_APP_MAIN_SITE }/comics/discover/`}
                    >
                        <span> GO TO DISCOVER </span>
                    </a>
                </div>

            </div>
        </div>
    );

};

NoBook.defaultProps = {
    isFree: false,
    isFreePromo: false,
    isMUUser: false 
};

NoBook.propTypes = {
    isFree: PropTypes.bool,
    isFreePromo: PropTypes.bool,
    isMUUser: PropTypes.bool
};

export default NoBook;