import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { manageDataLayer, uatuActions } from '../../hooks/uatu';

const SpotlightSlide = ( props ) => {
    const {
        slideNum,
        digitalId,
        title,
        thumbnailPath,
        creatorsShort
    } = props;

    // ADOPS IS NO LONGER TRACKING
    const _onDiscoverClick = useCallback(() => {
        manageDataLayer({
            type: uatuActions.REPORT_READ_MORE,
            payload: {
                from: 'discover',
                digitalId,
                title
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Link
                to = { `/book/${ digitalId }` }
                className="back"
                data-from="discover"
                data-nextid={ digitalId }
                data-nexttitle={ title }
                onClick = { _onDiscoverClick }
                replace
            >
                <img
                    src={`${ thumbnailPath }/portrait_incredible.jpg`}
                    alt={ title }
                />
            </Link>
            <span className="number">
                { slideNum + 1 }
            </span>
            <aside>
                <h2>
                    { title }
                </h2>
                <h4>
                    {creatorsShort}
                </h4>
            </aside>
        </div>
    );
};

SpotlightSlide.propTypes = {
    slideNum: PropTypes.number,
    digitalId: PropTypes.number,
    title: PropTypes.string,
    thumbnailPath: PropTypes.string,
    creatorsShort: PropTypes.string
};

export default SpotlightSlide;