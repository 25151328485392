import React from 'react';
import PropTypes from 'prop-types';

const ClipPath = ( props ) => {
    return (
        <defs id={`${props.id}-defs`}>
            <clipPath
                id={props.id}
            >
                <rect
                    id={props.rectId}
                    width={props.width}
                    height={props.height}
                    x={props.x}
                    y={props.y}
                />
            </clipPath>
        </defs>
    );
};

ClipPath.propTypes = {
    id: PropTypes.string,
    rectId: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number
};

export default ClipPath;