import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Fields from "./Fields";
import { Link } from "react-router-dom";
import "./next-book.scss";
import { manageDataLayer, uatuActions } from "../hooks/uatu";
import NoBook from "./NoBook";

const NextBook = (props) => {
  const {
    thumbnail,
    title,
    id,
    releaseDateFormatted,
    muReleaseDateFormatted,
    rating,
    creators,
    imprint,
    format,
    features,
    isFree,
    isFreePromo,
    isMUUser
  } = props;

  // ADOPS IS NO LONGER TRACKING
  const _onNextClick = useCallback(() => {
    manageDataLayer({
      type: uatuActions.REPORT_READ_MORE,
      payload: {
        from: "next",
        id,
        title,
      },
    });
  }, [id, title]);

  let noBookClass = isMUUser ? "defaultView" : "freePromoView";

  return (
    <div id="next_issue">
      <div className="thumbnail">
        <img src={thumbnail.path + "/portrait_uncanny.jpg"} alt={title} />
      </div>
      <div className="content">
        <h3> NEXT ISSUE IN SERIES </h3>
        <aside>
          <h2>{title || "undefined"}</h2>
          {typeof id !== "undefined" && (
            <Link
              to={`/book/${id}`}
              className="read_now back"
              data-from="next"
              data-nextid={id}
              data-nexttitle={title}
              onClick={_onNextClick}
            >
              <span> READ NOW </span>
            </Link>
          )}
        </aside>
        <article>
          <Fields data={releaseDateFormatted} str="Published" />
          <Fields
            data={muReleaseDateFormatted}
            str="Added to Marvel Unlimited"
          />
          <Fields data={rating} str="Rating" />
          <Fields data={creators} />
          <Fields data={imprint} str="Imprint" />
          <Fields data={format} str="Format" />
          <Fields data={features} str="Features" />
        </article>
      </div>
      <div className={noBookClass}>
        {isFree && 
          <NoBook
            isFree={isFree}
            isFreePromo={isFreePromo}
            isMUUser={isMUUser}
          />
        }
      </div>
    </div>
  );
};

NextBook.propTypes = {
  thumbnail: PropTypes.object,
  creators: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.number,
  releaseDateFormatted: PropTypes.string,
  releaseDateDigitalFormatted: PropTypes.string,
  rating: PropTypes.string,
  imprint: PropTypes.string,
  format: PropTypes.string,
  features: PropTypes.string,
  muReleaseDateFormatted: PropTypes.string,
  isFree: PropTypes.bool,
  isFreePromo: PropTypes.bool,
  isMUUser: PropTypes.bool
};

export default NextBook;
