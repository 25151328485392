import React, { useContext } from 'react';
import useKeyboardShortcut from "../hooks/useKeyboardShortcut";
import useFullscreenStatus from '../hooks/useFullscreenStatus';
import { AppContext } from '../hooks/appContext';
import PropTypes from 'prop-types';

const Footer = (props) => {
    const { readerContext, readerState, footerClass } = props;
    const {
        moveForwardInBook,
        moveBackwardInBook,
        togglePaneling,
        toggleZoomMode
    } = useContext( readerContext );
    const {
        idleTimeoutStatus
    } = useContext( AppContext );
    const {
        isIdle
    } = idleTimeoutStatus;
    const {
        isSmartPanelingOn,
        isInZoomMode,
        isFooterLocked,
        curBook
    } = readerState;
    
    const { toggleFullscreen, isFullscreen } = useFullscreenStatus();

    const toggleState = curBook.issue_meta.format === "Infinite Comic" ? "disable" : "";

    useKeyboardShortcut( [ 'ArrowRight' ], moveForwardInBook );
    useKeyboardShortcut( [ 'ArrowLeft' ], moveBackwardInBook );
    useKeyboardShortcut( [ 'z' ], toggleZoomMode );
    useKeyboardShortcut( [ 's' ], togglePaneling );

    return (
        <section id="footer">
            <footer className={`
                ${ ( isSmartPanelingOn ) ? 'panel-on ' : '' }
                ${ ( isInZoomMode ) ? 'zoom-on ' : '' }
                ${ ( isFullscreen ) ? 'fullscreen-on' : '' }
                ${ ( !isFooterLocked && isIdle ) ? 'slideAway' : '' }
                ${ footerClass ? footerClass : '' }
            `}>
                <nav>
                    <div
                        id="left_arrow"
                        className="icon arrow"
                        onClick={ moveBackwardInBook }
                        role="button"
                        tabIndex="0"
                        aria-label="left"
                    />
                    <div className="tools">
                        <div
                            className={`icon btn-panel ${toggleState}`}
                            onClick={ togglePaneling }
                            role="button"
                            tabIndex="0"
                            aria-label="panel"
                        />
                        <div
                            className="icon btn-zoom"
                            onClick = { toggleZoomMode }
                            role="button"
                            tabIndex="0"
                            aria-label="zoom"
                        />
                        <div
                            className="icon btn-screen"
                            onClick = { toggleFullscreen }
                            role="button"
                            tabIndex="0"
                            aria-label="fullscreen"
                        />
                    </div>
                    <div
                        id="right_arrow"
                        className="icon arrow"
                        onClick={ moveForwardInBook }
                        role="button"
                        tabIndex="0"
                        aria-label="right"
                    />
                </nav>
            </footer>
        </section>
    );
};

Footer.propTypes = {
    readerContext: PropTypes.object,
    readerState: PropTypes.object,
    footerClass: PropTypes.string
};

export default Footer;