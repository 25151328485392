import React, { createContext, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    verticalReaderReducer,
    initialVerticalReaderState,
    verticalReaderActions
} from "./verticalReaderReducer";
import useUatuForVertReader from './useUatuForVertReader';

export const VerticalReaderContext = createContext();

export const VerticalReaderProvider = ( props ) => {
    const {
        curBookId,
        curBook,
        children
    } = props;

    const initialState = {
        ...initialVerticalReaderState,
        curBook: curBook,
        curBookId: curBookId
    };

    const [ verticalReaderState, verticalReaderDispatch ] = useReducer(
        verticalReaderReducer,
        initialState
    );
    // eslint-disable-next-line no-unused-vars
    const analyitcs = useUatuForVertReader({
        verticalReaderState
    });

    const closeEndScreen = useCallback(() => {
        verticalReaderDispatch({
            type: verticalReaderActions.CLOSE_ENDSCREEN
        });
    }, [ verticalReaderDispatch ]);

    const toggleBookInfoOpen = useCallback(() => {
        verticalReaderDispatch({
            type: verticalReaderActions.TOGGLE_BOOK_INFO_OPEN
        });
    }, [ verticalReaderDispatch ]);

    const toggleTooltipsOpen = useCallback(() => {
        verticalReaderDispatch({
            type: verticalReaderActions.TOGGLE_TOOLTIPS_OPEN
        });
    }, [ verticalReaderDispatch ]);

    return (
        <VerticalReaderContext.Provider
            value = {{
                verticalReaderState,
                verticalReaderDispatch,
                closeEndScreen,
                toggleBookInfoOpen,
                toggleTooltipsOpen
            }}
        >
            { children }
        </VerticalReaderContext.Provider>
    );
};
VerticalReaderProvider.propTypes = {
    curBook: PropTypes.object.isRequired,
    curBookId: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired
};
