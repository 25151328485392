import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { manageDataLayer, uatuActions } from '../../hooks/uatu';

const AllPages = ( props ) => {
    const {
        pages,
        numberOfPages,
        curPageNum,
        goToPageByNumber,
        toggleAllPagesOpen
    } = props;


    const _goToPageAndCloseView = useCallback(( pageNum ) => {
        goToPageByNumber( pageNum );
        toggleAllPagesOpen();
        manageDataLayer({
            type: uatuActions.REPORT_TOC_CLICK,
            payload: {
                pageNumber: pageNum,
                trackData: {
                    pageNumber: pageNum
                }
            }
        });
    },[
        goToPageByNumber,
        toggleAllPagesOpen
    ]);

    const pageThumbs = useMemo(() => {
        const thumbEls = [];
        for( let i = 0; i < pages.length; i++ ) {
            const thumb = pages[ i ].thumbnail;
            const readability = i < numberOfPages ? 'readable' : 'locked';
            const pageNum = i + 1;
            const pageNumDisplay = ( i < 9 ) ? '0' + pageNum : '' + pageNum;

            thumbEls.push(
                <div
                    className = { `thumbs ${ readability }${ ( curPageNum === i ) ? ' activePage' : '' }` }
                    data-index = { i }
                    key = { `thumb-${ i }`}
                    onClick = { ( readability === 'readable' ) ? () => _goToPageAndCloseView( i ) : null }
                    role="button"
                    tabIndex="0"
                >
                    <div
                        className="img-div"
                        style = {
                            ( readability === 'readable' ) ?
                                {
                                    backgroundImage: `url("${ thumb }")`
                                } :
                                null
                        }
                    />
                    <span>
                        { pageNumDisplay }
                    </span>
                </div>
            );
        }
        return thumbEls;
    }, [
        pages,
        numberOfPages,
        curPageNum,
        _goToPageAndCloseView
    ]);

    return (
        <div id="overlayRelativer">
            <div className="_DPOP_mask fullPageView _DPOP_hasMask"></div>
            <div id="dPopWin_wrap" className="_DPOP_window_wrap bringDPopUpIn">
                <div
                    id="dPopWin"
                    className="rocket-dpop _DPOP_window"
                >
                    <div className="thumbs-wrap">
                        { pageThumbs }
                    </div>
                </div>
            </div>
        </div>
    );
};

AllPages.propTypes = {
    pages: PropTypes.array,
    numberOfPages: PropTypes.number,
    curPageNum: PropTypes.number,
    goToPageByNumber: PropTypes.func,
    toggleAllPagesOpen: PropTypes.func
};

export default AllPages;