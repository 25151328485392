import React, { useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
import './App.css';
import './scss/main.scss';
import PrintBookReader from './components/print-reader/PrintBookReader';
import VerticalComicReader from './components/vertical-reader/VerticalComicReader';
import ErrorPage from './components/ErrorPage';
import { AppContext } from './hooks/appContext';
import { PrintReaderProvider } from './hooks/printReaderContext';
import { VerticalReaderProvider } from './hooks/verticalReaderContext';
import { useMatch } from "react-router-dom";
import { appActions } from "./hooks/appReducer";
import BifrostService from './services/bifrost.service';
import get from 'lodash/get';
import NoPreviewBuyPage from './components/NoPreviewBuyPage';

const _bifrostService = BifrostService.getService();

const getParameterByName = ( name, url ) => {
    if (!url) {
        url = window.location.href;
    }

    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const App = () => {
    const {
        appState,
        appDispatch
    } = useContext( AppContext );
    const match = useMatch( '/:bookSourceType/:id' );
    const toBookId = ( match && match.params ) ? String( match.params.id ) : null;
    const bookSourceType = ( match && match.params ) ? String( match.params.bookSourceType ) : null;

    let curPageNum = getParameterByName( 'page' );
    let curPanelNum = getParameterByName( 'panel' );
    let formatParam = getParameterByName( 'format' );
    curPageNum = ( curPageNum ) ? parseInt( curPageNum ) - 1 : null;
    curPanelNum = ( curPanelNum ) ? parseInt( curPanelNum ) - 1 : null;
    formatParam = (formatParam === 'print' || formatParam === 'vertical') ? formatParam : null;

    const {
        isBookLoading,
        curBookId,
        curBook,
        digitalFormat,
        errors,
        didErrorOut 
    } = appState;

    const shouldLoadNewBook = !!(
        !isBookLoading &&
        toBookId &&
        !appState.didErrorOut &&
        String( toBookId ) !== String( curBookId )
    );
    const isFree = get( curBook, 'is_free' ) || false;
    const isLoggedIn = get( curBook, 'auth_state.logged_in' ) || false || isFree;
    const isMUUser = get( curBook, 'auth_state.subscriber' ) || false || isFree;
    const shouldShowBook = ( isLoggedIn && isMUUser );
    //Supports format query param to display different format types using Grootbook
    const displayFormat = ( bookSourceType === 'grootbook' && formatParam !== null ) ? formatParam : digitalFormat;

    if(shouldShowBook) {
        window.digitalData.userInfo.userId = get( curBook, 'auth_state.user_id' ) + "";
    }

    useEffect(() => {
        const _loadBookById = async ( toBookId ) => {
            if(shouldLoadNewBook) {
                window.scrollTo({ top: 0 });
                appDispatch({
                    type: appActions.START_BOOK_LOAD,
                });
                const book = await _bifrostService.loadBookById( toBookId, bookSourceType, displayFormat );
                if( book.error || book.didErrorOut ) {
                    appDispatch({
                        type: appActions.REPORT_ERROR,
                        payload: {
                            book
                        }
                    });
                } else {
                    appDispatch({
                        type: appActions.BOOK_LOADED,
                        payload: {
                            book
                        }
                    });
                }

            } else {
                if (this.state.curBook) {
                    appDispatch({
                        type: appActions.BOOK_LOADED,
                        payload: {
                            book: curBook
                        }
                    });
                }
            }
        };

        if( shouldLoadNewBook ) {
            _loadBookById( toBookId );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ shouldLoadNewBook, digitalFormat ]);

    return (
        <div id="rocket-reader">
            {isBookLoading && createPortal(
                <div className="page-loading-animation" />,
                document.body
            )}
            {
                ( didErrorOut || !toBookId ) &&
                    <ErrorPage
                        errorCode = { get( errors, "issueError.code" ) }
                    />
            }
            {
                ( shouldShowBook && curBook && digitalFormat === 'print' && !didErrorOut) &&
                    <PrintReaderProvider
                        key = { 'print-book-' + curBookId }
                        curBook = { curBook }
                        curBookId = { curBookId }
                        curPageNum = { curPageNum }
                        curPanelNum = { curPanelNum }
                    >
                        <PrintBookReader key = { curBookId } />
                    </PrintReaderProvider>
            }
            {
                ( shouldShowBook && curBook && digitalFormat === 'vertical' && !didErrorOut) &&
                    <VerticalReaderProvider
                        key = { 'vetical-comic-' + curBookId }
                        curBook = { curBook }
                        curBookId = { curBookId }
                        curPageNum = { curPageNum }
                        curPanelNum = { curPanelNum }
                    >
                        <VerticalComicReader key = { curBookId } />
                    </VerticalReaderProvider>
            }
            {
                ( !isBookLoading && !shouldShowBook && !( didErrorOut || !toBookId ) ) &&
                    <NoPreviewBuyPage
                        isLoggedIn = { isLoggedIn }
                        isMUUser = { isMUUser }
                    />
            }
        </div>
    );
};

export default App;
