import { useContext, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { VerticalReaderContext } from "./verticalReaderContext";
import { verticalReaderActions } from "./verticalReaderReducer";

const useLazyLoadStatus = (props) => {
  const [el, setEl] = useState(null);
  const [hasIntersected, setHasIntersected] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const { verticalReaderDispatch } = useContext(VerticalReaderContext);
  const { elId, height, panelNum } = props;

  const _onElIntersection = useCallback((e) => {
    const isI = e[0].isIntersecting;
    if (isI) {
      setHasIntersected(true);
      verticalReaderDispatch({
        type: verticalReaderActions.SCROLL_PROGRESS,
        payload: {
          panelLoaded: panelNum,
        },
      });
    }
    setIsIntersecting(isI);
    // if( isI ) {
    // console.log( 'hi: ' + elId );
    // }
  }, [panelNum, verticalReaderDispatch]);

  useEffect(() => {
    let elObserver;
    if (el) {
      elObserver = new IntersectionObserver(_onElIntersection, {
        root: null,
        rootMargin: height + "px",
        threshold: 0,
      });
      elObserver.observe(el);
    }
    return () => {
      if (elObserver) {
        elObserver.disconnect();
      }
    };
  }, [el, _onElIntersection, height]);

  useEffect(() => {
    setEl(document.getElementById(elId));
  }, [elId]);

  return {
    hasIntersected,
    isIntersecting,
  };
};

useLazyLoadStatus.propTypes = {
  elId: PropTypes.string.isRequired,
};

export default useLazyLoadStatus;
