export const appActions = {
    START_BOOK_LOAD: 'startLoad',
    BOOK_LOADED: 'loadBook',
    REPORT_ERROR: 'reportError'
};

export const initialAppState = {
    curBook: null,
    digitalFormat: null,
    didErrorOut: false,
    curBookId: null,
    curBookSourceType: null,
    isBookLoading: false,
    errors: null
};

export const appReducer = ( state, action ) => {
    // BOOK LOADING ACTIONS
    switch( action.type ) {
        case appActions.START_BOOK_LOAD:
            state = {
                ...initialAppState,
                isBookLoading: true,
                isBookReadyToRead: false
            };
            break;

        case appActions.BOOK_LOADED:
            state = {
                ...state,
                curBook: action.payload.book,
                curBookId: String( action.payload.book.id ),
                isBookLoading: false,
                digitalFormat: action.payload.book?.issue_meta?.reader_format || "print",
                bookType: action.payload.book?.issue_meta?.format,
            };
            break;

        case appActions.REPORT_ERROR:
            state = {
                ...initialAppState,
                didErrorOut: true,
                errors: action.payload.book
            };
            break;
        default:
            break;
    }

    return state;
};
