import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { manageDataLayer, uatuActions } from '../hooks/uatu';

const BuyPage = ( props ) => {
    const _onLinkClick = useCallback(( url, type ) => {
        manageDataLayer({
            type: uatuActions.REPORT_LINK_CLICKED,
            payload: {
                type,
                url
            }
        });
    },[]);
    return (
        <div>
            <div className={`login-link ${props.isLoggedIn ? "disabled" : ""}`}>
                Already a Marvel Unlimited Member?
                <a
                    href={ process.env.REACT_APP_LOGIN_URL }
                > Log in</a>.
            </div>

            <h1>Unlock the book</h1>
            <h2>Get instant access to over 30,000 Marvel digital comics</h2>
            <div className="cta-tag">Only $9.99 a month!</div>
            <a
                className = "btn btn-red getitnow"
                href = { process.env.REACT_APP_CHECKOUT }
                onClick = {() => {
                    _onLinkClick( process.env.REACT_APP_CHECKOUT, 'sell:join' );
                }}
            >
                <span>
                    GET IT NOW!
                </span>
            </a>
            <div className="legaleese">
                Cancel any time. Auto-renewal and other terms and conditions apply. Please see&nbsp;
                <a
                    href={`${ process.env.REACT_APP_MAIN_SITE }/unlimited`}
                    onClick = {() => {
                        _onLinkClick(
                            `${ process.env.REACT_APP_MAIN_SITE }/unlimited`,
                            'sell:unlimited'
                        );
                    }}
                >
                    Marvel.com/unlimited
                </a>
                    &nbsp;for details and the&nbsp;
                <a
                    href={`${ process.env.REACT_APP_MAIN_SITE }/corporate/marvel_unlimited_terms`}
                    onClick = {() => {
                        _onLinkClick(
                            `${ process.env.REACT_APP_MAIN_SITE }/corporate/marvel_unlimited_terms`,
                            'sell:terms'
                        );
                    }}
                >
                    Marvel Unlimited Terms of Use
                </a>
                &nbsp;for applicable terms of service.
            </div>
        </div>
    );
};

BuyPage.propTypes = {
    isLoggedIn: PropTypes.bool
};

export default BuyPage;